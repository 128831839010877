.menu {
  ul {
    padding: 0;
    margin: 0;

    li {
      display: inline-block;
      padding: 0;
      margin-right: 23px;
      &:last-child {
        margin-right: 0px;
      }

      a {
        text-decoration: none;
        text-transform: uppercase;
        display: block;
        height: $header-height;
        padding-top: 50px;
        line-height: 21px;
        letter-spacing: 0.4px;

        &.bold,
        span b {
          font-weight: 500;
        }
      }

      &.grey {
        &.active a span {
          border-bottom: none;
        }

        span {
          color: $color-grey;
        }
      }

      &:hover,
      &.active {
        > a span {
          padding-bottom: 5px;
          border-bottom: 1px solid $color-black;
        }
      }
    }
  }

  @include mq($from: tablet, $until: wide) {
    white-space: nowrap;
  }

  @include mq($until: tablet) {
    display: none;
  }
}