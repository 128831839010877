.free-shipping-bar {
  width: 100%;
  height: 55px;
  background-color: #f3f3f3;
  text-align: center;
  display: table;
  margin-bottom: 30px;

  > span {
    display: table-cell;
    font-weight: 400;
    text-transform: uppercase;
    vertical-align: middle;
    letter-spacing: 1px;
    font-size: 11px;
    b {
      font-weight: 600;
    }
    i {
      letter-spacing: 1px;
      font-weight: 500;
      border-bottom: 1px solid #969393;
    }
  }
}

.free-shipping-bar {
  width: 40%;
  height: 37px;
  margin-bottom: 0;
  background-color: transparent;
  position: absolute;
  left: 30%;
  top: 0;
  z-index: 1;

  @include mq($from: wide) {
    width:500px;
    left:50%;
    margin-left:-250px;
  }

  @include mq($until: tablet) {
    width: 100%;
    left: 0;
    background-color: black;
  }

  > span {
    color: #fff;

    i {
      font-style: normal;
      border-bottom: 1px solid #969393;
    }
  }
}