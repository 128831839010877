.box {
  margin-bottom: 25px;
  padding-bottom: 25px;
  position: relative;
  @include clearfix;

  &.list {
    margin-bottom: 0px;
    padding-bottom: 0px;
    cursor: pointer;

    ul.less,
    .price-div .col-xs-12 {
      padding-bottom: 50px;
    }
    .slimScrollDiv {
      margin-bottom: 50px;
    }
  }

  &.half {
    > .inner {
      min-height: 175px;
    }
  }

  &.one {
    > .inner {
      min-height: 317px;
    }
  }

  &.two {
    > .inner {
      min-height: 500px;
    }
  }

  &.one,
  &.two {
    > .inner {
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  @include mq($from: tablet) {
    &.no-bottomMargin {
      margin-bottom: 0 !important;
    }
  }

  &.no-bottomPadding {
    padding-bottom: 0 !important;
  }

  &.no-bottom {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
  }

  > .header {
    //padding: 0px 15px;
    margin-bottom: 5px;

    #account-page & {
      padding-left: 15px !important;
    }

    > div {
      display: inline-block;
      vertical-align: middle;
      position: relative;
    }

    .title {
      font-weight: bold;
      font-size: 28px;
      color: black;
      text-transform: uppercase;
      margin-right: 26px;
      letter-spacing: 2px;

      @include mq($until: tablet) {
        line-height: 28px;
        font-size: 24px;
      }

      a {
        color: black;
      }

      i {
        &.filter-margins {
          margin: 0px 7px 0px 4px;
        }
      }
    }

    .rating {
      @extend .pull-right;
      text-align: center;
      font-size: 10px;
      letter-spacing: 2px;

      @include mq($until: tablet) {
        display: none;
      }

      span {
        display: block;
        font-size: 26px;
        line-height: 24px;
        font-weight: bold;
      }
    }

    @include mq($until: mobile) {
      .view-more {
        float: right;
        margin-right: 15px;
      }
    }

    &.less-margin {
      margin-bottom: 0 !important;
    }

    .right {
      float: right;

      > div {
        display: inline-block;
        vertical-align: middle;
      }

      @include mq($until: tablet) {
        width: 100%;
        text-align: right;
      }

      .sort-group {
        margin-left: 5px;
        margin-top: -5px;
        position: relative;
      }
    }

  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    line-height: 25px;
    @include clearfix;

    &.block {
      li {
        display: block;
        float: none;
      }
    }

    li {
      float: left;
      position: relative;
    }
  }

  &.cms {
    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
    ul {
      list-style: circle;
      padding-left: 40px;

      li {
        float: none;
      }
    }
  }
}