.justified {
  > div,
  > li {
    display: table-cell;
    width: 1%;
    float: none !important;
    text-align: center;

    > a {
      width: 90% !important;
    }
  }
}