footer {
    #newsletter .input-group input[type='email'] {
        border-radius: 3px !important;
        width: 97% !important;
        height: 36px;
    }
    #newsletter .input-group button {
        border-radius: 3px !important;
        padding: 8px 12px;
    }
    a:hover {
        text-decoration: none;
    }
    padding: 52px 0 10px 0;
    background-color: $background-light-grey;
    &.small {
        padding: 0;
        background-color: white;

        @include mq($until: tablet) {
            padding-top: 15px;
            padding-bottom: 15px;
        }

    }
    .fa-facebook-square {
        background-image: url('/../images/facebook.png');
        width: 32px;
        height: 32px;
    }

    .fa-facebook-square:before {
        content: "";
    }

    .fa-instagram {
        background-image: url('/../images/instagram.png');
        width: 32px;
        height: 32px;
    }

    .fa-instagram:before {
        content: "";
    }

    #newsletter {
        input[type='email'] {
            background-color: white;
        }

        .help-block {
            display: none;
        }

        .btn:focus {
            outline: 5px auto black;
        }

        .has-error {

            #newsletter-subscribe {
                border: 1px solid $state-danger-text;
                color: $state-danger-text;
            }

            .help-block {
                display: block;
                color: $state-danger-text;
            }
        }
    }

    @include mq($until: tablet) {
        .text-right {
            margin-bottom: 5px;
        }
    }

    .copyright {
        color: #aaa;
        font-size: 12px;

        @include mq($until: tablet) {
            padding-bottom: 0;
        }

        a {
            color: inherit;
        }
    }

    .social-holder {
        max-width: 140px;

        @include mq($until: tablet) {
            margin: auto;
        }
    }

    .img-responsive {
        width: 115px;
    }

    .title {
        text-transform: uppercase;
        font-weight: bold;
    }

    ul {
        @include clearfix;
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 14px;

        li {
            line-height: 24px;
        }
    }

    label {
        font-weight: normal;
        padding: 0;
        font-size: 14px;
    }

    @include mq($until: tablet) {
        text-align: center;

        .copyright > div {
            text-align: center !important;
            margin-bottom: 0 !important;
        }

        .list {
            text-align: center;
        }

        .row > div {
            margin-bottom: 20px;
        }
    }

    #newsletter input[type='email'] {
        @extend .form-control;
    }

    @import "social-links";
}

/* Mobile version for footer */
@media screen and (max-width: 1200px) {
    footer {
        padding: 0px 0 10px 0;

        .footer-mobile {
            display: block !important;
            margin-bottom: 15px;
            .social-links {
                margin-top: 15px;
                text-align: center;
            }
            .social-links a {
                padding: 0px 10px 0px 10px;
            }
            .app-links {
                text-align: center;
                a {
                    display: inline-block;
                }
            }
        }
        .newsletter-mobile {
            width: 100%;
            text-align: center;
            form {
                width: 50%;
                margin: 0 auto;
            }

        }
        .footer-mobile {
            a .mobile-link {
                width: 100%;
                height: 30px;
                line-height: 30px;
                padding: 0px 64px 0px 64px;
            }
        }
    }

    .footer-desktop {
        display: none !important;
    }
}

.footer-desktop {
    a .mobile-link {
        display: inline-block;
    }
}

.footer-mobile .collapse-block .title {
    display: none;
}

.footer-mobile .collapse-block ul li {
    margin: 5px 0px 5px 0px;
    text-align: left;
}

footer {
    .line {
        width: 100%;
        border-bottom: 1px solid #dadada;
    }
    button .title {
        color: #0d2027;
    }
    button .title i {
        float: right;
        font-size: 19px;
        font-weight: bold;
    }
    .footer-mobile {
        display: none;
    }
    .footer-mobile .menu-buttons button {
        width: 100%;
        border: none;
        background-color: #f3f4f4;
        text-align: left;
        padding: 12px 64px 2px;
    }
    .footer-desktop {
        display: block;
    }
    .social-links {
        margin-bottom: 20px;
    }
    .app-links {
        margin-bottom: 15px;
        a {
            display: block;
            margin-bottom: 5px;
        }
    }
}

/* Portrait phones and smaller */
@media (max-width: 480px) {
    footer {
        .newsletter-mobile form {
            width: 80%;
            margin: 0 auto;
        }
        hr {
            margin-top: 5px;
            border: 0;
            height: 0;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        }

        .list {
            text-align: left;
        }

        .list-social {
            a {
                float: right;
                margin-left: 15px;

                i {
                    font-size: large;
                }
            }

        }

        .copyright {
            padding-top: 0;
            padding-bottom: 0;
        }

        .all-rights {
            margin-top: 65px !important;
        }

    }
}
