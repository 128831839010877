#homepage {
  padding-top: 22px;

  @include mq($until: tablet) {
    padding-top: 0px;
  }

  .box > .header {
    margin-bottom: 20px;

    .title {
      margin-left: 15px;
    }
  }

  @include mq($from: tablet) {
    > .container-fluid {
      &:nth-child(2) {
        padding-top: 33px;
      }
    }
  }
}