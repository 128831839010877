.lookbook {

  &.layout1 {
    .block {
      height: 360px;
      position: relative;
      background-color: $background-light-grey;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 3px;

      @include mq($from: mobile) {
        position: absolute;

        &:nth-child(1) {
          height: 40%;
          left: 0;
          top: 0;
          width: 50%;
        }

        &:nth-child(2) {
          height: 20%;
          left: 50%;
          top: 0;
          width: 50%;
        }

        &:nth-child(3) {
          height: 20%;
          left: 50%;
          top: 20%;
          width: 50%;
        }

        &:nth-child(4) {
          height: 40%;
          left: 0;
          top: 40%;
          width: 100%;
        }

        &:nth-child(5) {
          height: 20%;
          left: 0;
          top: 80%;
          width: 50%;
        }

        &:nth-child(6) {
          height: 20%;
          left: 50%;
          top: 80%;
          width: 50%;
        }
      }

      @include mq($from: tablet) {
        position: absolute;

        &:nth-child(1) {
          height: 33.3%;
          left: 0;
          top: 0;
          width: 33.3%;
        }

        &:nth-child(2) {
          height: 33.3%;
          left: 0;
          top: 33.3%;
          width: 33.3%;
        }

        &:nth-child(3) {
          height: 33.3%;
          left: 0;
          top: 66.6%;
          width: 33.3%;
        }

        &:nth-child(4) {
          height: 66.6%;
          left: 33.3%;
          top: 0;
          width: 66.6%;
        }

        &:nth-child(5) {
          height: 33.3%;
          left: 33.3%;
          top: 66.6%;
          width: 33.3%;
        }

        &:nth-child(6) {
          height: 33.3%;
          left: 66.6%;
          top: 66.6%;
          width: 33.3%;
        }
      }

      @include mq($from: desktop) {
        &:nth-child(1) {
          height: 100%;
          left: 0;
          top: 0;
          width: 20%;
        }

        &:nth-child(2) {
          height: 50%;
          left: 20%;
          top: 0;
          width: 20%;
        }

        &:nth-child(3) {
          height: 50%;
          left: 20%;
          top: 50%;
          width: 20%;
        }

        &:nth-child(4) {
          height: 100%;
          left: 40%;
          top: 0;
          width: 40%;
        }

        &:nth-child(5) {
          height: 50%;
          left: 80%;
          top: 0;
          width: 20%;
        }

        &:nth-child(6) {
          height: 50%;
          left: 80%;
          top: 50%;
          width: 20%;
        }
      }
    }
  }

}