.product-list-top {
  @include mq($from: tablet) {
    &.noHeader {
      margin-top: -15px;
    }
  }
  //@include mq($from: tablet) {
  //  padding-top: 10px;
  //}

  .col-sm-4 {
    @include mq($until: tablet) {
      padding-left: 0;
      padding-right: 0;

      @include clearfix;
    }
  }

  .fixed-height-block {
    height: 65px; //40px
    display: table;

    @include mq($until: tablet) {
      height: auto;
      display: block;
      width: 100%;
    }

    &.sort {
      float: right;
      padding-top: 18px; //6px
      position: relative;

      @include mq($until: tablet) {
        margin-bottom: 25px;
        margin-top: -5px;
        padding-top: 0;
        display: block;

        @include clearfix;
      }
    }

    .title,
    .total {
      display: table-cell;
      vertical-align: middle;

      @include mq($until: tablet) {
        width: 100%;
        text-align: center;
        display: block;
      }
    }

    .title {
      font-size: 26px;
      padding-right: 15px;
      font-weight: 400;

      @include mq($until: tablet) {
        padding-right: 0;
      }
    }

    .total {
      font-size: 12px;
    }

    .btn {
      padding: 6px 7px 6px 7px;
      font-size: 12px;

      @include mq($until: tablet) {
        padding-top: 15px;
        padding-bottom: 15px;
        width: 50%;
        font-size: 14px;
        background-color: $background-light-grey;
        border-radius: 0;
        float: left;
        border-bottom: 1px solid $border-grey !important;

        &:first-child {
          border-right: 1px solid $border-grey !important;
        }
      }

      @include mq($from: desktop) {
        .fa {
          padding-left: 5px;
        }
      }

      &.btn-link {
        padding-right: 0;
        cursor: default;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .dropdown-toggle {
      &:hover {
        color: $color-black;
      }
    }

    .dropdown-menu {
      min-width: 140px;
      border: none;
      background-color: $background-light-grey;
      top: 47px;

      @include mq($until: tablet) {
        min-width: 100%;
      }

      li {
        float: none;

        &:first-child {
          padding-top: 6px;
        }
        &:last-child {
          padding-bottom: 6px;
        }

        a {
          padding: 3px 8px;
          font-size: 12px;
          font-weight: normal;

          @include mq($until: tablet) {
            padding: 8px 8px;
            font-size: 14px;
          }

        }
      }
    }
  }

  .product-list-filter-selected {
    .label {
      display: inline-block;
    }

    .filter-line {
      margin-bottom: 20px;

      #product-filter-remove-attributes {
        font-size: 12px;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
          text-decoration: none;
        }
      }

      .label-default {
        padding: 3px 8px;
        font-size: 12px;
        font-weight: 300;
        color: $color-black;
        border-radius: 3px;
        border: 1px solid $color-grey;
        background-color: white;
        margin-right: 10px;
        margin-bottom: 5px;

        &:first-child {
          margin-left: 6px;
        }

        i {
          font-size: 16px;
          font-weight: 300;
          color: $color-grey;
          opacity: 1;
          font-style: normal;
          margin-left: 10px;
          vertical-align: middle;
        }
      }
    }
  }
}
