.box.reviews-list {

  > .inner {

    .review {
      margin-bottom: 30px;

      .member-image {
        height: 50px;
        width: 50px;
        margin: auto;
        border-radius: 50%;
        overflow: hidden;
        position: absolute;
        left: 0;
        right: 0;
        margin-top: -25px;

        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
      }

      .member-name {
        padding-top: 40px;
        color: #bbb;
        margin-bottom: 5px;

        a {
          color: inherit;
        }
      }

      .time {
        color: #bbb;
      }

      .comment {
        margin-bottom: 5px;
        word-break: break-word;

        &.no-comment {
          color: #ccc;
          padding-top: 50px;

          span {
            display: block;
            color: #bbb;
            margin-top: 5px;
          }
        }
      }

      .product-title {
        color: $color-black;
        padding: 5px 0;
      }

      > .inside {
        background: #F5F5F5;
        padding: 0 25px 10px 25px;
        text-align: center;
        min-height: 250px;
        font-size: 14px;
      }

      .image {
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 360px;
        background-color: $background-light-grey;
        border-radius: 3px;
      }
    }
  }
}