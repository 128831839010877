.box.white-box {

  background: white;
  padding: 30px 15px;
  margin: 30px auto;

  &.empty {
    @include mq($until: tablet) {
      margin: 10px auto;
      padding: 15px 20px;
    }
  }

  &.cart_summary-margin {
    @include mq($until: desktop) {
      margin: 0;
    }
  }

  > .header {
    .title {
      margin: 0px 15px;
      margin-top: 0px;
    }

    &.no-margin {
      margin-bottom: 0px;
    }
  }

  > .footer {
    border-top: 1px solid #F3F3F3;
    padding-top: 35px;
    margin-top: 35px;
    text-align: center;
    text-transform: uppercase;

    a {
      font-weight: bold;
      border-bottom: 2px solid black;

      &:hover {
        border-bottom: 3px solid black;
        text-decoration: none;
      }
    }
  }
}