body {
  display: flex;
  min-height: 100vh;
  _height: 100vh;
  flex-direction: column;

  &.dark {
    background-color: $background-light-grey;
  }

  main {
    flex: 1 0 auto;
  }
  &.fixed {
    position: fixed;
    width: 100%;
    overflow: hidden;
  }
}
.padding-0 {
  padding: 0;
}

.btn:focus,
a:focus,
input:focus {
  outline: none !important;
  box-shadow: none !important;
}
input {
  box-shadow: none;
}
.white-bg {
  background: #fff;
}

.btn-confirm {
  color: #000;
  background-color: transparent;
  border-color: #000;
  border: 1px solid black;

  //&:hover {
  //  background-color: black;
  //  color: white;
  //}
  //
  //&:focus {
  //  background-color: black;
  //  color: white;
  //}
}
.clicked {
  background-color: black;
  color: white;
}


.row-seperator {
  > .row + .row {
    padding-top: 15px;
  }
}

.shadowed {
  &:hover {
    -webkit-box-shadow: 0 0 5px 0 rgba(170, 170, 170, 0.5);
    -moz-box-shadow: 0 0 5px 0 rgba(170, 170, 170, 0.5);
    box-shadow: 0 0 5px 0 rgba(170, 170, 170, 0.5);
  }
}

.product {
  .bordered {
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    border-bottom: 1px solid transparent;
    border-radius: 3px;
    padding-top: 13px;
    margin-top:-3px;
    min-height: 105px;
  }

  &:hover {
    .bordered {
      border-left: 1px solid #F2F2F2;
      border-right: 1px solid #F2F2F2;
      border-bottom: 1px solid #F2F2F2;
    }
  }
}



.pd-t-15 {
  padding-top: 15px;
}

.pd-t-20 {
  padding-top: 20px;
}

.pd-t-10 {
  padding-top: 10px;
}

.pd-b-15 {
  padding-bottom: 15px;
}

.pd-b-30 {
  padding-bottom: 30px;
}

.col-center {
  float: none;
  margin: 0 auto;
}

.padding-top-border {
  @include mq($until: desktop) {
    border-top: 1px solid #EFEFEF;
    padding-top: 10px;
  }
}

.btn-darkgray {
  background-color: $btn-gray-dark;
  color: #fff;
  font-weight: normal;
}

.btn-darkgray:hover {
  background-color: #000000;
  color: #fff;
}

.text-sm {
  font-size: 13px;
}
.text-xs {
  font-size: 12px;
}

.line-md {
  line-height: 30px;
}

.light-bold {
  font-weight: 500;
}

.sticky {
  position: fixed !important;
}

.form-group {
    &.has-error {
        input, select, .select2 {
            border: 1px solid $color-red;
            border-radius: 5px;
        }
    }

    &.checkbox-flex {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        input {
            flex-grow: 0;
            margin-top: 3px;
            margin-right: 4px;
        }
        label {
            max-width: calc(100% - 19px);
        }
        .help-block {
            width: 100%;
        }
    }
}




/**
BOOTSTRAP REWRITE
 */

/**
ALERTS
 */
.alert {
  color: white !important;
  margin-bottom: 0;
  border-radius: 0;

  .close {
    color: white;
    opacity: 1;

    &:hover,
    &:focus {
      color: white;
      opacity: 0.8;
    }
  }
}

.alert-success {
  @include alert-variant(#5A924B, $alert-success-border, $alert-success-text);
}

.alert-info {
  @include alert-variant(#0096E0, $alert-info-border, $alert-info-text);
}

.alert-warning {
  @include alert-variant(#E0C561, $alert-warning-border, $alert-warning-text);
}

.alert-danger {
  @include alert-variant(#EF290E, $alert-danger-border, $alert-danger-text);
}




/**
BREADCRUMBS
 */
.breadcrumb {
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 0;
  padding-left: 0;
  padding-bottom: 15px;
  padding-top: 15px;
  background-color: transparent;

  @include mq($until: tablet) {
    text-align: center;
    margin-bottom: 10px;
  }

  a {
    color: $breadcrumb-active-color;
  }

  > li {
    font-size: 12px;

    + li:before {
      color: $breadcrumb-active-color;
    }
  }
}


/**
BUTTONS
 */

.btn {
  border: none !important;

  .btn-default {
    border: none;
  }

}

// Black button
.btn-black {
  @include button-variant($btn-black-color, $btn-black-bg, $btn-black-border);
}
// Grey button
.btn-grey {
  @include button-variant($btn-grey-color, $btn-grey-bg, $btn-grey-border);
}

// Grey button
.btn-darkgrey {
  @include button-variant(#fff, #828B8E, $btn-grey-border);
  font-size: 18px;
}

// Greyer button
.btn-darkergrey {
  @include button-variant(#fff, #464646, $btn-grey-border);
  font-size: 18px;
}

// Facebook button
.btn-facebook {
  @include button-variant(#fff, #2c4385, $btn-grey-border);

  >span {
    white-space: normal;
  }
}
.btn-min100 {
  min-width: 100px;
}

.btn-md {
  // line-height: ensure even-numbered height of button next to large input
  @include button-size($padding-medium-vertical, $padding-medium-horizontal, $font-size-medium, $line-height-medium, $btn-border-radius-medium);
  //font-weight: bold;
  font-size: 14px !important;
}



/**
CLOSE
 */
.close {
  float: none;
  text-shadow: none;
  display: inline-block;
}

.alert button.close {
  position: absolute;
  right: 15px;
  height: 18px;
}



/**
FORMS
 */
label {
  cursor: pointer;
  font-weight: normal;
}
.label-black {
  @include label-variant($label-black-bg);
  color: #000;
}

.form-control {
  border: 0;

  &.white {
    background: white !important;
  }
}
.help-block {
  margin-top: 2px;
  font-size: 11px;
}
.form-horizontal {
  @media (min-width: $screen-sm-min) {
    .control-label {
      text-align: left;
    }
  }
}


/**
GRID
 */
.container-fluid {
  padding-left: 15px;
  padding-right: 15px;
  max-width: 1200px !important;

  &.login {
    max-width: 500px !important;
  }

  &.cart {
    max-width: 1030px !important;
  }
}

.fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  background: red;
}



.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}

.col-xs-15 {
  width: 20%;
  float: left;
}


@media (min-width: 768px) {
  .col-sm-15 {
    width: 20%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-15 {
    width: 20%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-15 {
    width: 20%;
    float: left;
  }
}


/**
NAVS
 */
.nav {
  > li {
    > a {
      &:focus {
        background-color: transparent;
      }
    }
  }
}
.nav-tabs {
  border-bottom: none;
  > li {
    > a {
      color: #ccc;
      border: none;

      &:hover {
        color: #000;
        background: transparent;
        border-color: transparent;
      }
    }

    &.active > a {
      &,
      &:hover,
      &:focus {
        color: #000;
        background-color: transparent;
        border: none;
        background: transparent;
      }
    }
  }
}


/**
NORMALIZE
 */
mark {
  background: transparent;
  color: inherit;
}

/**
PAGINATION
 */
.pagination {
  .glyphicon-menu-right, .glyphicon-menu-left {
    font-size: 10px;
  }

  > li {
    > a,
    > span {
      padding: 8px 16px;
    }
  }

  > .disabled {
    > span,
    > span:hover,
    > span:focus,
    > a,
    > a:hover,
    > a:focus {
      border-color: transparent;
    }
  }
}



/**
TABLES
 */
.table {
  > thead,
  > tbody,
  > tfoot {
    > tr {
      &:first-child {
        > th,
        > td {
          border-top: 0;
        }
      }
    }
  }

  > thead > tr > th {
    background: $background-light-grey;
    vertical-align: bottom;
    border-bottom: 2px solid $table-border-color;
  }
}


/**
MODAL
 */
.modal-backdrop {
  z-index: 1200 !important;
}

.modal {
  padding-right: 0 !important;
  z-index: 1201 !important;

  .close {
    position: absolute;
    right: 25px;
    top: 20px;
    border: 1px solid $color-grey;
    opacity: 1;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: $color-grey;
  }

  @include mq($from: desktop) {
    .modal-dialog {
      margin-top: 160px;
    }
  }

  @include mq($from: wide) {
    .modal-lg {
      width: 920px;
    }
  }
  @include mq($until: tablet) {
    .modal-content {
      min-height: 100vh;
    }
  }

  .modal-body {
    padding: 30px;
  }
}
#cookieModal {
  .modal-dialog {
    max-width: 400px;
  }

  .range {
    background-color: black;
    position: absolute;
    top: 0;
    height: 100%;
    width: 10px;
    left: 1px;
    z-index: 99;
  }

  .relative {
    position: relative;
  }

  .flexed-column {
    .row {
      cursor: pointer;
    }
  }

  .range-dot {
    width: 30px;
    height: 30px;
    position: absolute;
    background: black;
    border-radius: 30px;
    left: -20px;
    top: -5px;
    cursor: pointer;
    z-index: 100;
  }
  //.flexed-column {
  //  display: flex;
  //  flex-direction: column;
  //  height: 560px;
  //}
  #gdpr-types {
    position: relative
  }
  .flexed-column {
    .row {
      //height: 194px;
      margin-left: 0;
      border-left: 10px solid black;
      padding-bottom: 15px;
    }
  }

  @include mq($until: mobile) {
    .flexed-column {
      .row {
        height: auto !important;
      }
    }
    .mb-xs-2 {
      margin-bottom: 1em;
    }
  }
}

@media (min-width: 62em) {
  #cookieModal {
    .modal .modal-dialog {
      margin-top: 50px !important;
    }
  }
}

.wishlist-add {
  cursor: pointer;
  .fa {
    color: #fff;
    font-size: 20px;
  }
  @include mq($from: desktop) {
    &:hover {
      .fa {
        color: #000;
      }
    }
  }
  &.added {
    .fa {
      color: #000;
    }
    @include mq($from: desktop) {
      &:hover {
        .fa {
          color: #fff;
        }
      }
    }
  }
}

#wishlist-page {
  #wishlist-items {
    margin-top: 30px;
  }
  .text-subtitle, .text-title {
    text-transform: uppercase;
    font-weight: bold;
  }
  .text-subtitle {
    margin-top: 20px;
    font-size: 12px;
  }
  .text-title {
    font-size: 24px;
  }

  #wishlist-empty {
    margin-bottom: 20px;
    .fa-heart.fa {
      margin-top: 40px;
      font-size: 90px;
      color: #e7e7e7;
    }
    .text-wardrobe {
      text-transform: uppercase;
      font-weight: bold;
      margin-top: 20px;
      font-size: 20px;
    }
    .text-small {
      line-height: 24px;
      font-size: 12px;
      font-weight: bold;
    }
  }
}

.center-block-bt {
  float: none;
  margin: 0px auto;
}

#navUp {
  position: fixed;
  right: 10%;
  bottom: 110px;
  z-index: 2;

  display: block;
  width: 36px;
  height: 36px;
  border: 1px solid;
  border-radius: 2px;
  //background-color: #ffffff;
  cursor: pointer;
  @media (max-width: 991px) {
    bottom: 50px;
    right: 10px;
  }
  &:after {
    content: "";
    display: block;
    margin-top: 6px;
    margin-left: 7px;
    height: 20px;
    width: 20px;
    background-repeat: no-repeat;
    background-size: 20px 20px;

    content: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaWQ9IkxheWVyXzEiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUwIDUwOyIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgNTAgNTAiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxnIGlkPSJMYXllcl8xXzFfIj48cG9seWdvbiBwb2ludHM9IjI1LDE1LjI2OCA0Ny4yOTMsMzcuNTYgNDguNzA3LDM2LjE0NyAyNSwxMi40NCAxLjI5MywzNi4xNDcgMi43MDcsMzcuNTYgICIvPjwvZz48L3N2Zz4=);
  }
  &:hover {
    background-color: #dedede;
    &:after {
      content: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaWQ9IkxheWVyXzEiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUwIDUwOyIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgNTAgNTAiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxnIGlkPSJMYXllcl8xXzFfIj48cG9seWdvbiBwb2ludHM9IjI1LDE1LjI2OCA0Ny4yOTMsMzcuNTYgNDguNzA3LDM2LjE0NyAyNSwxMi40NCAxLjI5MywzNi4xNDcgMi43MDcsMzcuNTYgICIvPjwvZz48L3N2Zz4=);
    }
  }
}

.mb-1 {
  margin-bottom: 0.5em;
}
.mb-2 {
  margin-bottom: 1em;
}

.mt-1 {
  margin-top: 1em;
}
.mt-2 {
  margin-top: 1.5em;
}

.mt-0 {
  margin-top: 0;
}
.bolded {
  font-weight: bold;
}

@include mq($until: mobile) {
  .pr-0-mobile {
    padding-right: 0 !important;
  }
}

.esto-container {
  &__image {
    display: inline-block;
    vertical-align: top;
    width: 20%;
  }
  &__text {
    padding: 0px 5px;
    display: inline-block;
    width: 75%;
    @media (max-width: 620px) {
      width: 100%;
    }
  }
}

.line-or {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #f3f3f3;
  line-height: 0.1em;
  margin: 35px 0 35px;
}

.line-or span {
  background:#fff;
  padding:0 10px;
}



