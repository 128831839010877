// This hides the suggestion double input
.Typeahead-input.tt-hint {
  //visibility: hidden!important;
  opacity: 0.1 !important;
}

.search {
  display: none;
  position: relative;
  width: auto;

  @include mq($until: desktop) {
    display: inline-block;
  }
  //padding-top: 15px;
  //margin-right: 22px;
  //vertical-align: middle;
  //width: 100%;
  //max-width: 240px;
}
#search {
  //position: absolute;
  width: 100%;
  z-index: 1;
  background-color: #ffffff;

  position: relative;
  display: inline-block;
  width: auto;
  padding-top: 15px;
  margin-right: 22px;
  vertical-align: middle;
  //margin-top: 15px;

  @include mq($until: desktop) {
    display: none;
    position: absolute;
    left: 0px;
    right: 0px;
    padding: 18px 46px 15px 14px;
    margin-right: 0px;
    border-top: 1px solid #eee;
  }

  #search-query {
    width: 240px;
    padding: 5px 30px 5px 16px;
    letter-spacing: 0.3px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: width 0.3s linear;
    float: right;
    border-width: 1px;
    border: 1px solid #DCDCDC;
    border-radius: 2px;


      &:focus, &:active {
        @include mq($from: desktop) {
          width: 220px;
        }
        @include mq($from: 1035px) {
          width: 245px;
        }
        @include mq($from: 1055px) {
          width: 255px;
        }
        @include mq($from: 1075px) {
          width: 265px;
        }
        @include mq($from: 1095px) {
          width: 275px;
        }
        @include mq($from: 1120px) {
          width: 280px;
        }
        @include mq($from: 1170px) {
          width: 310px;
        }
        @include mq($from: wide) {
          width: 265px;
        }
      }

    @include mq($until: desktop) {
      width: 100%;
      padding-left: 12px;
    }

    @include mq($from: desktop, $until: 1034px) {
      width: 200px;
    }
  }

  .loader {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: white;
    display: block;
    top: 0;
    left:0;
    z-index: 110;
    opacity: 0.9;

    i {
      font-size: 30px;
      position: absolute;
      left:50%;
      top:50%;
      margin: -15px 0 0 -15px;
      line-height: 30px;
    }
  }

  form {
    //position: relative;
    > i {
      position: absolute;
      right: 9px;
      top: 24px;
      &.fa-times {
        right: 0px;
        top: 8px;
        padding: 18px;
        cursor: pointer;
      }
      @include mq($until: desktop) {
        right: 55px;
        top: 26px;
      }
    }
  //
  //  .search_loading {
  //    display: none;
  //    position: absolute;
  //    right: 0;
  //    z-index: 1000;
  //    top: 50px;
  //    font-size: 27px;
  //
  //    @include mq($until: tablet) {
  //      top: 30px;
  //      font-size: 20px;
  //    }
  //  }
  //
    input {
      width: 100%;
      //border: 0;
      //font-size: 50px;
      color: #000;
      padding: 5px 30px 5px 16px;
      letter-spacing: 0.3px;
      border-width: 1px;
      left: 0px;
      @include mq($until: desktop) {
        padding-left: 13px;
      }
      //@include placeholder(#E2E2E2);

      &.tt-hint {
        @include mq($until: desktop) {
          top: 18px !important;
          left: 14px !important;
          width: calc( 100% - 60px );
          padding-left: 12px;
        }
        //font-size: 20px;
        //padding-left: 0;
        //padding-right: 0;
      }
    }

    .twitter-typeahead {
      width: 100%;

      @include mq($until: desktop) {
        position: initial !important;
      }

      .tt-menu {
        display: none !important;
        text-align: left;
        border-radius: 2px;
        //position: relative !important;

        &.tt-open:not(.tt-empty) {
          display: block !important;
          width: 100%;
          border-top: none;
          border-radius: 0px;

          @include mq($until: desktop) {
            height: calc( 100vh - 221px );
            overflow: scroll;
            background-color: #ffffff;
          }
          @include mq($until: tablet) {
            height: calc( 100vh - 158px );
          }
          @include mq($from: desktop) {
            box-shadow: 0 2px 4px 0px rgba(0,0,0,0.3);
          }
        }

        .tt-dataset {
          width: 100%;
          padding-top: 7px;
          padding-bottom: 7px;
          background-color: #fff;
          @include mq($until: desktop) {
            padding-top: 0px;
          }

          .EmptyMessage {
            padding: 0px 28px;
          }

          .tt-selectable {
            padding: 6px 16px;
            letter-spacing: 0.4px;
            cursor: pointer;

            @include mq($until: desktop) {
              padding: 6px 27px;
            }

            .result {
              font-weight: 500;
            }

            .type {
              color: #CCCCCC;
              font-weight: 200;

              @include mq($until: tablet) {
                text-align: right;
              }
            }
            &:hover {
              background-color: #F4F4F4;
            }
          }

          .tt-cursor {
            background: #f7f7f7;
          }
        }
      }
    }

    .search-action-link {
      display: block;
      position: absolute;
      top: 15px;
      right: 0px;
      height: 32px;
      width: 32px;

      @include mq($until: desktop) {
        top: 18px;
        right: 46px;
      }

      i:before {
        display: block;
        margin: 9px 8px;
      }
    }
  }
}

#search-overlay {
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.80);
  z-index: 1199;
  position: fixed;
}