//// HACK: Required for the visual functionality
.shopping-bag.active {
  span {
    border-bottom: none !important;
  }
}

.shopping-bag {
  @media (max-width: 950px) {
    position:relative;
  }

  .title {
    font-size:14px;

    // HACK: Because client needs
    @include mq($until: wide) {
      display:none;
    }
  }

  .fa {
    margin-right: 5px;
  }

  .amount {
    @media (max-width: 950px) {
      display:none;
    }
  }

  .counter {
    @media (max-width: 950px) {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      background-color: #ef290e;
      position: absolute;
      bottom: 11px;
      right: -5px;
      font-size: 10px;
      line-height: 14px;
      font-weight: 200;
      text-align: center;
      color: #fff!important;
    }
  }
}

i.fa.shopping-bag-bg {
  background-image: url('../../../images/shopping_bag_icon.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 11px;
  height: 15px;
}