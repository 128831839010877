#cms-form {
  background: #f5f5f5;

  @include mq($from: desktop) {
    margin-left: -15%;
    margin-right: -15%;
    padding: 30px 15%;
  }

  textarea {
    resize: none;
  }

  #phone, #birthday {
    @include mq($from: desktop) {
      width: 60%;
    }
  }
}

.box.two.catalogues {
  @include mq($until: tablet) {
    .inner {
      min-height: initial;

      .box.no-bottomMargin {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
}