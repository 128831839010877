.help-center {
  height: 37px;
  line-height: 37px;
  font-weight: 500;

  @include mq($until: tablet) {
    display: none;
  }

  > span {
    color: #fff;


    i {
      font-style: normal;
      font-size: 12px;
    }

    a {
      color:#ffffff;
    }

    .mobile-link {
      text-decoration: none;
      font-size: 12px;
      padding-left: 15px;
    }
  }
}