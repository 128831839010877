.shippingMethodList {
  font-size: 14px;
  margin-top: 5px;
}

.shippingMethodListHolder {
  border-bottom: 1px solid #f3f3f3;
  margin-bottom: 30px;
  padding-bottom: 20px;

  @include mq($until: desktop) {
    input[type='text'] {
      text-indent: 10px;
    }
  }

  &.cart-holder {
    @include mq($until: desktop) {
      margin-bottom: 10px;
    }
  }
}

.shippingMethodListItem {
  padding-bottom: 8px;
  padding-top: 8px;
  cursor: pointer;
  position: relative;

  @include mq($until: tablet) {
    padding-left: 15px;
  }

  &:hover, &.active {
    background: #f7f7f7;

    .itemTitle {
      font-weight: bold;
    }
  }

  .itemRadio {
    margin-right: 5px;

    @include mq($until: tablet) {
      position: absolute;
      left: 10px;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }

  .itemTitle {
    color: #05181e;

    @include mq($until: tablet) {
      position: static;
    }
  }

  .itemDescription {
    color: #9a9fa1;

    @include mq($from: desktop) {
      padding: 0;
    }

    @include mq($until: tablet) {
      line-height: 12px;
      font-size: 10px;
    }
  }

  .itemPrice {
    color: #05181e;
    text-align: right;
    font-weight: bold;
  }
}

.shippingMethodFields {

  .form-group {
    margin-bottom: 10px;

    .col-sm-4 {
      input[name='city'] {
        margin-bottom: 5px;
      }
    }
  }

  .title {
    font-size: 18px;
    color: #05181e;
    margin-bottom: 15px;
    text-transform: uppercase;
    font-weight: 500;
  }

  .subField {
    @include mq($from: tablet) {
      padding-left: 0;
      padding-right: 10px;
      margin-left: -5px;
    }
  }

  .fieldInfo {
    line-height: 36px;
    font-size: 12px;
    color: #9a9fa1;
    @include mq($from: tablet) {
      padding-left: 0;
    }

    a {
      color: inherit;
      border-bottom: 1px solid #9a9fa1;
      text-decoration: none !important;
    }
  }
}

.hasShippingMethodInfo {
  background-color: #fdffd5;
  padding: 15px 15px 15px 35px;
  font-size: 12px;
  position: relative;

  @include mq($from: tablet) {
    position: absolute;
    width: 33%;
    right: 0px;
    top: 40px;
  }

  &:before {
    content: "\f129";
    position: absolute;
    left: 15px;
    top: 15px;
    font-family: FontAwesome;
    font-size: 18px;
    color: #cacdac;
  }

  .infoTitle {
    font-weight: bold;
  }

  .infoText {

  }
}

.hasShippingMethodInfoCartDone {
  background-color: #fdffd5;
  padding: 15px 15px 15px 35px;
  font-size: 12px;
  position: relative;
  max-width: 210px;

  &:before {
    content: "\f129";
    position: absolute;
    left: 15px;
    top: 15px;
    font-family: FontAwesome;
    font-size: 18px;
    color: #cacdac;
  }

  .infoTitle {
    font-weight: bold;
  }

  .infoText {

  }
}

.cart_page_addressForm{
  .info {
    min-height:115px;
  }
  .info .info-field {
    padding: 2px 0;

    &.first_name_field {
      display: inline-block;
    }

    &.last_name_field {
      display: inline-block;
    }
  }

  form.form-edit.form-horizontal {
    padding-bottom: 30px;

    @include mq($until: tablet) {
      &.padding-b-0 {
        padding-bottom: 0;
      }
    }

  }

  button#openEdit {
    margin-top: 10px;
  }
}

.box.category.cart-banner {
  padding: 0;
  .inner {
    min-height: 400px;
    .title-middle {
      top: auto;
      bottom: 80px;
    }
  }
}


@import "../components/cart/payment";