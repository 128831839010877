/**
 * BEGIN: Lookbook
 */
@import "lookbook_layouts/layout1";

.lookbook-bg {
  margin-bottom: 40px;
  padding: 50px 0 50px;
  background-color: $background-light-grey;

  .container-fluid {
    @include mq($until: mobile) {
      padding-right: 15px;
      padding-left: 15px;
    }
  }

  .image-profile-lookbook {
    background-size: cover;
    background-position: center;

    @include mq($from: mobile) {
      display: block;
    }
  }

  #lookbook-location-desktop {
    @include mq($until: tablet) {
      margin-top: -155px;
    }

    @include mq($until: mobile) {
      margin-top: -53px;
    }
  }

  .the-lookbook .col-xs-12.slick-slide {
    @include mq($until: mobile) {
      padding-right: 0px;
      padding-left: 0px;
    }
  }
  
}

.lookbook {
  position:relative;

  &.box {
    margin-bottom: 125px;

    @include mq($until: mobile) {
      margin-bottom: 0px;
    }
  }

  @include mq($until: mobile) {
    padding-right: 15px;
    padding-left: 15px;
  }

  @include mq($from: mobile) {
    height: 1563px;
  }

  @include mq($from: tablet) {
    height: 925px;
  }

  @include mq($from: desktop) {
    height:490px;
  }

  .block {

    .delete-product {
      position: absolute;
      bottom: 0px;
      right: 0px;
    }

    .circle {
      position: absolute;
      top: 0%;
      left: 0%;
      height: 27px;
      width: 27px;
      line-height: 25px;
      background-color: #fff;
      border-radius: 50%;
      text-align: center;

      &:before {
        content: "+";
        font-size: 16px;
        font-weight: bold;
      }

      .no-touch & {
        &:hover {
          .hover {
            display: block;
          }
        }
      }

      .hover {
        display: none;
        position: absolute;
        bottom: 32px;
        background: white;
        z-index: 10;
        left: -66px;
        padding: 9px 15px;
        text-align: left;
        width: 152px;
        line-height: 20px;

        .product-name {
          text-transform: uppercase;
        }

        .brand-name {
          color: #aaa;
          font-size: 12px;
        }
      }
    }
  }

  .footer {
    position: relative;
    text-align: center;

    .faded {
      color: #999;
      .comment {
        font-style: normal;
        font-size: 13px;
      }
    }

    &.in-slider {

    }

    .image {
      width: 55px;
      height: 55px;
      display: block;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 50%;
      overflow: hidden;
      z-index: 9999;
      position: absolute;
      left: 0px;
      right: 0px;
      top: -30px;
      margin: auto;
    }

    .title {
      padding-top: 30px;
      line-height: 26px;
      font-weight:400;
      font-size: 22px;
      font-style: italic;
    }

    .comment {
      color: #999;
    }
  }
}

.the-lookbook {
  .title {
    h3 {
      line-height: 26px;
      font-weight: 400;
      font-size: 22px;
      font-style: italic;
      margin-bottom: 0px;
    }
  }
}