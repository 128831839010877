.brand-list-ul {
  display: inline-flex;
  flex-wrap: wrap;
  margin-bottom: 10px !important;
  width: 100%;

  .brand-list-li {
    padding-left: 0px;
    width: 25%;

    @include mq($until: tablet) {
      width: 33.333333333333%;
    }

    @include mq($until: mobile) {
      width: 100%;
    }
  }
}

.alphabet {
  padding: 7px;
  background-color: #F3F3F3;
  margin-bottom: 40px;
}