.cart-login-register-wrp {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: -5px;

  .cart-login-wrp, .cart-register-wrp {
    flex-grow: 0;
    padding: 25px 15px;
    width: 100%;
    background-color: #FFFFFF;
  }
  @media (min-width: 992px) {
    flex-direction: row;
    gap: 0px;
    margin-top: 15px;
    padding: 30px 5px;
    background-color: #FFFFFF;

    .cart-login-wrp, .cart-register-wrp {
      padding: 0px 25px;
      width: 50%;
    }
    .cart-login-wrp {
      border-right: 1px solid #DCDCDC;
    }
  }
}
.simple-login, .cart-register, .simple-login-register {
  .header {
    margin-bottom: 26px;

    .title {
      display: block;
      padding: 5px 0px 15px 0px;
      font-size: 20px;
      line-height: 23px;
      font-weight: bold;
      border-bottom: 1px solid #f3f3f3;
      text-transform: uppercase;
    }
  }

  .form-group {
    margin-bottom: 10px;
  }
  .control-label {
    margin-bottom: 4px;
  }
  .form-control {
    height: 38px;
  }

  .or {
    padding: 0px 0px 19px 0px;
    text-align: right;
  }

  .forgot-password {
    font-size: 14px;
    border-bottom: 1px solid #000;

    &:hover {
      text-decoration: none;
    }
  }

  .btn {
    text-transform: uppercase;
  }

  .wrapOpenBtn {
    margin-top: 14px;
    border-radius: 4px;
  }
  .wrapOpenBtnGrey {

  }
}
.simple-login.cart-done-box {
  padding: 20px 30px;

  .row {
    margin-left: -7px;
    margin-right: -7px;
  }
  .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    padding-left: 7px;
    padding-right: 7px;
  }
  .header {
    margin-bottom: 22px;

    .title {
      font-weight: bold;
      font-size: 20px;
      line-height: 23px;
      border: none;
    }
  }
  .form-group {
    margin-bottom: 14px;
  }
}
.social-actions-wrp {
  .social-title {
    padding-top: 10px;
    margin-bottom: 0px;
    font-size: 14px;
    line-height: 20px;
  }
  .row {
    margin-left: -7px;
    margin-right: -7px;
  }
  .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    padding-left: 7px;
    padding-right: 7px;
  }
  .login-separator {
    position: relative;
    margin: 35px 0px;
    height: 1px;
    width: 100%;
    background-color: #DCDCDC;

    > span {
      position: absolute;
      top: 50%;
      left: 50%;
      padding: 5px 10px;
      font-size: 14px;
      line-height: 20px;
      background-color: #ffffff;
      transform: translate(-50%, -50%);
    }
  }
}
.registerOpenWrap {
  > p {
    margin-bottom: 22px;
  }
  @media (min-width: 992px) {
    > p {
      margin-bottom: 14px;
    }
  }
}
.register-separator {
  margin: 24px 0px;
  border-top: 1px solid #DCDCDC;

  @media (min-width: 992px) {
    margin: 15px 0px;
  }
}
.simple-login-register {
  margin-top: 30px;
}
.cart-register {
  .btn-success {
    margin-bottom: 10px;
  }
}
@media (min-width: 992px) {
  .simple-login {
    display: block !important;
  }
}

.social-login {
  @include mq($from: desktop) {
    padding-top: 30px;
  }

  a {
    position: relative;
    @include mq($from: desktop) {
      width: 80%;
      margin: auto;
    }
    font-weight: 300;
  }
  .btn-block {
    i:not(.fa-spinner) {
      padding-right: 13px;
    }
  }
  padding-bottom: 15px;
}

.social-login-line {
  @include mq($until: desktop) {
    background: $background-light-grey;
    height: 1px;
    margin-bottom: 30px;
  }
}

.auth-form {
  padding-bottom: 35px;

  > .inner {
    > .row {
      margin-left: -25px;
      margin-right: -25px;

      > .col-xs-1, > .col-sm-1, > .col-md-1, > .col-lg-1, > .col-xs-2, > .col-sm-2, > .col-md-2, > .col-lg-2, > .col-xs-3, > .col-sm-3, > .col-md-3, > .col-lg-3, > .col-xs-4, > .col-sm-4, > .col-md-4, > .col-lg-4, > .col-xs-5, > .col-sm-5, > .col-md-5, > .col-lg-5, > .col-xs-6, > .col-sm-6, > .col-md-6, > .col-lg-6, > .col-xs-7, > .col-sm-7, > .col-md-7, > .col-lg-7, > .col-xs-8, > .col-sm-8, > .col-md-8, > .col-lg-8, > .col-xs-9, > .col-sm-9, > .col-md-9, > .col-lg-9, > .col-xs-10, > .col-sm-10, > .col-md-10, > .col-lg-10, > .col-xs-11, > .col-sm-11, > .col-md-11, > .col-lg-11, > .col-xs-12, > .col-sm-12, > .col-md-12, > .col-lg-12 {
        padding-left: 25px;
        padding-right: 25px;
      }
    }
  }
}