//== Colors
$color-black:             #05181e !default;
$color-grey:              #c3c3c3 !default;
$color-red:               #cf131d;
$color-green:               #5a924b;
//$color-grey:              #9A9FA1;
$color-line:              #F3F3F3;
$link-color:              $color-black;

$gray-base:               #000 !default;
$gray-darker:             lighten($gray-base, 13.5%) !default; // #222
$gray-dark:               lighten($gray-base, 20%) !default;   // #333
$gray:                    lighten($gray-base, 33.5%) !default; // #555
$gray-light:              lighten($gray-base, 46.7%) !default; // #777
$gray-lighter:            lighten($gray-base, 93.5%) !default; // #eee

// Borders colors
$border-grey:             #ebebeb !default; // #eee

// Backgrounds colors
$background-grey:         #e4e4e4 !default; //f2f2f2 - there is one more background color
$background-light-grey:   #f3f3f4 !default;

$brand-primary:           $color-black !default;
$brand-success:           #5A924B !default;
$brand-info:              #5bc0de !default;
$brand-warning:           #f0ad4e !default;
$brand-danger:            #cf131d !default;
$brand-black:             #FFFFFF !default;
$btn-gray-dark:           #b4b9bb !default;   // #b4b9bb


//== Typography
$font-size-base:          14px;
$font-family-sans-serif:  'Roboto', sans-serif !default;
$font-family-serif:       'Roboto', sans-serif !default;
$font-size-medium:         ceil(($font-size-base * 1.15)) !default;


//== Iconography
$icon-font-path: "/fonts/" !default;


//== Components
$padding-medium-vertical:    10px !default;
$padding-medium-horizontal:  18px !default;
$line-height-medium:         1.4444444 !default;
$border-radius-base:        3px !default;
$border-radius-medium:      3px !default;
$border-radius-large:       3px !default;
$border-radius-small:       3px !default;


//== Tables
$table-border-color:            #F3F3F3 !default;


//== Buttons
$btn-black-color:                #fff !default;
$btn-black-bg:                   #191919 !default;
$btn-black-border:               #000 !default;
$btn-grey-color:                #000 !default;
$btn-grey-bg:                   #F3F3F3 !default;
$btn-grey-border:               #000 !default;
$btn-border-radius-medium:       $border-radius-medium !default;


//== Forms
$input-bg:                       #F3F3F3 !default;
$input-color:                    #000 !default;
$input-border:                   #ccc !default;
$input-color-placeholder:        #9C9FA0 !default;


//-- Z-index master list
$zindex-modal-background:  1104 !default;
$zindex-modal:             1105 !default;


//== Media queries breakpoints
$container-fluid-max-width:  1200px;


//== Pagination
$pagination-bg:                        #F3F3F3 !default;
$pagination-border:                    #FFFFFF !default;
$pagination-hover-color:               $link-color !default;
$pagination-hover-bg:                  #E0E0E0 !default;
$pagination-hover-border:              #FFFFFF !default;
$pagination-active-color:              $link-color !default;
$pagination-active-bg:                 #E0E0E0 !default;
$pagination-active-border:             #FFFFFF !default;
$pagination-disabled-bg:               transparent !default;


//== Labels
$label-black-bg:              $brand-black !default;


//== Modals
$modal-inner-padding:         10px 20px 40px 100px !default;
$modal-lg:                    750px !default;


//== Breadcrumbs
$breadcrumb-active-color:       #9f9f9f !default;


//== Code
$code-color:                  #cf131d !default;


// Custom
$text-shadow:                 0px 0px 5px #aaa;
$menu-content-border-color:   #f5f5f5;
$global-border-radius:        3px;
$header-title-shadow: 0px 1px 2px rgba(0, 0, 0, 0.6);
//$cart-bg:                     #F3F3F3;

// Header
$header-height: 113px;
$header-height-mobile: 50px;
$cursor-disabled:                default !default;

