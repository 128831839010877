.vertical-align {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;

  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  > div,
  > span {
    width: 100%;

    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -webkit-flex-pack: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;

    -webkit-flex-line-pack: center;
    -ms-flex-line-pack: center;
    -webkit-align-content: center;
    align-content: center;

    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}