#bar {
  background: black;
  min-height: 37px;
  position: relative;
  z-index: 1201;

  .container-fluid {
    @import "sites";
    @import "help";
    @import "free-shipping";
    @import "languages";
    @import "currencies";
  }
}
