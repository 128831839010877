.address-list {
  //padding-bottom: 10px !important;
  margin-bottom: 15px !important;

  li {
    border: 1px solid #ececec;
    padding: 10px 5px;
    margin-bottom: 5px;
    float: none !important;
    @include clearfix;

    .left {

      span {
        display: block;
      }
    }

    .right {

    }
  }
}