.social-links {
  font-size: 18px;

  > a {


    &:hover {
      text-decoration: none;
    }

    &:first-child {

    }
  }
}

.payment-icons {

  padding-top: 58px;
  width: 180px;
  padding-left: 0;

  .visa {
    background:url('/../icons/visa.png') no-repeat;
    width:34px;
    height:22px;
    background-size: 34px 22px;
    margin-right: 10px;
  }

  .paypal {
    background:url('/../icons/paypal.png') no-repeat;
    width:34px;
    height:22px;
    background-size: 34px 22px;
    margin-right: 10px;
  }

  .mastercard {
    background:url('/../icons/mastercard.png') no-repeat;
    width:34px;
    height:22px;
    background-size: 34px 22px;
    margin-right: 10px;
  }

  @media only screen and (min--moz-device-pixel-ratio: 2),

    only screen and (-o-min-device-pixel-ratio: 2/1),

    only screen and (-webkit-min-device-pixel-ratio: 2),

    only screen and (min-device-pixel-ratio: 2) {

      .visa {

        background-size:cover;
      }

      .paypal {
        background-size:cover;
      }

      .mastercard {
        background-size:cover;
      }

    }

}