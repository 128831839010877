.box.product-list {
  .inner {
    > div {
      margin-bottom: 30px;

      .product {
        position: relative;
        margin-top: 25px;
        margin-bottom: 20px;

        &.margin-t-0 {
          @include mq($until: tablet) {
            margin-top: 0;
          }
        }

        .soldout {
          font-size: 14px;
          color: $color-red;
        }

        .labels-top {
          position: absolute;
          top: 10px;
          left: 10px;
          text-align: left;
        }
        .labels {
          position: absolute;
          bottom: 10px;
          left: 10px;
          text-align: left;
        }

        .on-hover {
          position: absolute;
          top: 15px;
          right: 30px;
          text-align: right;
          display: none;
        }

        .image {
          border-top: 1px solid transparent;
          border-left: 1px solid transparent;
          border-right: 1px solid transparent;
        }

        .no-touch & {
          &:hover {
            .on-hover {
              z-index: 999;
              display: block;
            }

            .additional {
              display: block !important;
            }

            .image {
              border-top: 1px solid #F2F2F2;
              border-left: 1px solid #F2F2F2;
              border-right: 1px solid #F2F2F2;
            }
            .brand-name{
              a {
                text-decoration: none;
              }
            }
          }
        }

        .image {
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          height: 360px;
          background-color: $background-light-grey;
          position: relative;
          border-radius: 3px;
          overflow: hidden;
        }

        .title {
          padding-bottom: 10px;
          font-weight: normal;

          a {
            color: inherit;
            text-decoration: none;
          }
        }

        .title.cart {
          padding-bottom: 10px;
          font-weight: 500;

          a {
            color: inherit;
          }
        }

        .specifications {
          margin-bottom: 5px;
          padding-left: 0px;
        }

        a.delete {
          position: absolute;
          bottom: 0;

          &.cart-button {
            text-decoration: underline;
            color: #b7b7b7;
          }
        }

        .brand-name {
          text-transform: uppercase;
          font-weight: normal;
        }

        .brand-name.cart {
          text-transform: uppercase;
          font-weight: 500;
        }

        .info {
          @include clearfix;

          .additional {
            float: right;
            height: 20px;
            overflow: hidden;
            display: none;

            &.has-more {
              border-bottom: 1px dashed #C5C5C5;
            }

            @include mq($until: desktop) {
              display: none;
            }

            > div {
              display: inline-block;
            }

            .select {
              ul {
                li {
                  margin-left: 10px;
                  font-size: 12px;
                  line-height: 20px;
                }
              }
            }

            .color {
              vertical-align: top;
              margin-left: 10px;

              ul {
                display: inline-block;

                li {
                  width: 10px;
                  height: 10px;
                  display: inline-block;
                  border: 1px solid #ccc;
                  margin-right: 3px;

                  &:last-child {
                    margin-right: 0px;
                  }
                }
              }
            }
          }

          .sold-out {
            color: $color-red;
            font-size: 11px;
          }
        }
      }
    }
  }
}

.price-holder {
  float: left;

  @include clearfix;

  &.cart {
    font-size: 12px;
    font-weight: bolder;

    &.discount {
      float: right;

      .price,
      .old-price {
        text-align: right;
        margin-right: 0;
      }

      &.cart-price {
        padding-left: 0px;
        width: 100%;
        margin-bottom: 5px;
      }
    }


  }

  &.block {
    float: none;
  }

  > div {

    @include mq($until: desktop) {
      float: none;
    }

    @include mq($from: desktop) {
      float: left;
    }

  }

  @at-root #cart .popup-cart-list {
    .price, .old-price {
      float: none;
    }
  }

  &.discount {
    .price {
      color: $color-red;
      margin-right: 10px;

      #cart_page & {
        width: 100%;
        margin-right: 0;
        text-align: right;
      }
    }

    .old-price {
      text-decoration: line-through;
      margin-right: 10px;

      #cart_page & {
        width: 100%;
        margin-right: 0;
        text-align: right;
      }
    }

    .old-price-red {
      text-decoration: line-through;
      text-decoration-color: $color-red;
    }

    .normal-price {
      #cart_page & {
        width: 100%;
      }
    }

    .one-left {
      padding-left: 10px;
      color: $color-red;
      font-size: 11px;
      line-height: 22px;
    }
  }
}

#cart_summary_box {
  .price,
  .old-price {
    margin-right: 0 !important;
    text-align: left !important;
  }
}

.cart-item:not(:last-child) {
  border-bottom: 1px solid #f3f3f3;
}