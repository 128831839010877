table {
  &.no-border {
    tr,
    td,
    th {
      border-top: none !important;
    }
  }

  &.no-margin {
    margin-bottom: 0px !important;
  }
}