.order {
  margin-bottom: 0;
  padding: 0;

  @include mq($until: tablet) {
    margin-right: -15px;
    margin-left: -15px;

    .list,
    .summary {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .back {
    .fa {
      padding-right: 5px;
      font-size: 12px;
    }
  }

  .list-items {
    margin-top: 19px;
    margin-bottom: 17px;
    line-height: 20px;
    border-bottom: 1px solid #F3F3F3;

    .header {
      padding: 10px;
      background-color: #E3E3E3;
      font-weight: bold;

      @include mq($until: tablet) {
        margin-left: 0;
        margin-right: 0;
      }
    }
    .item {
      padding-top: 15px;

      &:last-child {
        padding-bottom: 15px;
      }
      @media (min-width: 992px) {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    .image {
      width: 70px;
      height: 94px;
      float: left;
      margin-right: 10px;
      margin-bottom: 0;
      background-size: cover;
    }

    .text-muted {
      color: #aaa;
      font-size: 12px;
      line-height: 12px;

      @include mq($until: tablet) {
        padding-top: 10px;
      }
    }
  }

  .summary {
    .shipping {
      padding-bottom: 15px;
    }

    .title {
      @include mq($until: tablet) {
        text-align: left;
      }
    }
  }
}
#cart_page {
  &.order-view-guest {
    .box.white-box {
      margin-top: 10px;
      padding-bottom: 30px;

      > .header {
        margin-bottom: 22px;

        .title {
          padding-top: 10px;
          padding-bottom: 12px;
          font-weight: bold;
          font-size: 20px;
          line-height: 23px;
        }
      }
      .list {
        .item {
          display: flex;
          gap: 25px;
          padding: 5px 0px;
          //padding-bottom: 15px;
          overflow: hidden;

          .title {
            flex-grow: 0;
            flex-shrink: 0;
            width: 120px;
          }
        }
      }
      @media (min-width: 992px) {
        margin-top: 0px;

        .list {
          .item {
            .title {
              width: 150px;
            }
          }
        }
      }
    }
  }
}
