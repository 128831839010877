.dataTable {
  a {
    margin: 0px 6px;
    border-bottom: 1px solid black;

    &:last-child {
      margin-right: 0px;
    }

    &:hover {
      text-decoration: none;
    }
  }

  tr {
    td, th {
      padding: 10px 15px !important;

      &:last-child {
        text-align: right;
      }
    }

    &:last-child {
      td {
        border-bottom: 1px solid #F3F3F3;
      }
    }

  }
}

#orders {
 .page {
    > .header {
      padding-left: 0px;
      margin-bottom: 10px;
    }
 }
}