#feedback {
  position: fixed;
  z-index: 3;
  right: 10%;
  bottom: -390px;
  max-width: 350px;
  min-height: 430px;
  background-color: #dde0e3;
  -webkit-transition-duration: 0.35s;
  -moz-transition-duration: 0.35s;
  -ms-transition-duration: 0.35s;
  -o-transition-duration: 0.35s;
  transition-duration: 0.35s;

  .feedback-slide {
    color: #ffffff;
    font-family: 'Roboto', sans-serif;
    font-size: 17px;
    font-weight: 500;
    line-height: 40px;
    min-height: 40px;
    padding: 0 15px;
    background-color: #000000;
    cursor: pointer;
  }

  #feedback-disable {
    float: right;
    img {
      width: 20px;
    }

  }
  .feedback-content {
    padding: 13px 15px 15px;
    .feedback-top {
      color: #000000;
      font-family: 'Roboto', sans-serif;
      font-size: 17px;
      font-weight: 300;
      line-height: 23px;
      display: table;
      width: 100%;
      p {
        margin: 0;
      }
      >ul {
        list-style: decimal;
        padding-left: 15px;
        margin-top: 8px;
        margin-bottom: 0;
        >li {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
    .feedback-form {
      display: table;
      width: 100%;
      margin-top: 18px;
      form {
        >input.input-white {
          color: rgba(0,0,0,0.7);
          font-family: 'Roboto', sans-serif;
          font-size: 16px;
          font-weight: 300;
          line-height: 23px;
          border: 1px solid transparent;
          width: 100%;
          height: 30px;
          padding: 0 10px;
          margin-bottom: 8px;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
        }
        >textarea {
          color: rgba(0,0,0,0.7);
          font-family: 'Roboto', sans-serif;
          font-size: 16px;
          font-weight: 300;
          line-height: 23px;
          border: 1px solid transparent;
          width: 100%;
          height: 105px;
          padding: 15px 10px;
          margin-bottom: 8px;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          resize: none;
        }
        >.file-upload {
          float: left;
          width: 50%;
        }
        >.btn-send {
          float: right;
          width: 50%;
          position: relative;

          input[type="submit"] {
            color: rgba(255,255,255,0.7);
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            font-weight: 400;
            text-align: center;
            background-color: #000000;
            border: none;
            -webkit-border-radius: 2px;
            -moz-border-radius: 2px;
            border-radius: 2px;
            float: right;
            min-width: 70px;
            margin-top: 4px;
            padding: 7px 10px;
            cursor: pointer;
          }

          .loader {
            position: absolute;
            width: 26px;
            height: 26px;
            display: block;
            bottom: 4px;
            right: 20px;
            z-index: 110;
            opacity: 0.9;

            img {
              width: 24px;
            }
          }
        }
        >input.input-white.feedback-error {
          border-color: #ff0000;
        }
        >textarea.feedback-error {
          border-color: #ff0000;
        }
      }
    }
  }
}
#feedback.open {
  bottom: 0;
}
.file-upload {
  .box-upload {
    .inputfile {
      width: 1px;
      height: 1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
    padding-top: 9px;
    padding-bottom: 9px;
    >label {
      background-image: url('/images/icon-upload.png');
      background-size: 17px 20px;
      background-repeat: no-repeat;
      background-position: center left;
      display: inline-block;
      cursor: pointer;
      >span {
        color: #000000;
        font-family: 'Roboto', sans-serif;
        font-size: 11px;
        font-weight: 400;
        padding-left: 25px;
        text-decoration: underline;
      }
    }
    >label.upload-error {
      background-image: url('/images/icon-upload-error.png');
      >span {
        color: #ff0000;
      }
    }
  }
}
.feedback-errors {
  color: #ff0000;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 400;
  margin-top: -18px;
  margin-bottom: 6px;
  display: block;
}
#mail-status {
  .success {
    color: #000000;
    font-family: 'Roboto', sans-serif;
    font-size: 17px;
    font-weight: 400;
    background-color: #ffffff;
    padding: 15px;
    position: relative;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    >span {
      color: #000000;
      font-family: 'Roboto', sans-serif;
      font-size: 15px;
      font-weight: 700;
      display: block;
      padding-top: 10px;
    }
    &:after {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      bottom: -20px;
      right: 45px;
      border-style: solid;
      border-width: 0 20px 20px 0;
      border-color: transparent #ffffff transparent transparent;
      line-height: 0;
      _border-color: #ffffff #ffffff #ffffff #ffffff;
      _filter: progid:DXImageTransform.Microsoft.Chroma(color='#ffffff');
    }
  }
}
