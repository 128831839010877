#review {
  textarea {
    resize: none;
    width: 100%;
    height: 100px;
  }

  .form-control {
    width: 100%;
  }
}