.box.page {

  &.no-side {
    > .inner > .main {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  > .inner {
    @include clearfix;

    > .side {

      @include mq($from: tablet) {
        padding-left: 0px;
      }

      .box.list {
        border-bottom: 1px solid #ccc;
        margin-bottom: 25px;
        padding-bottom: 25px;

        .header {
          margin-bottom: 0px;
          padding: 0px 12px;
          margin-bottom: 12px;

          .title {
            font-size: 14px;
            position: relative;

            &:before {
              font-family: FontAwesome;
              content: "\f0dd";
              margin-left: -10px;
              margin-right: 8px;
              vertical-align: top;
              line-height: 15px;
            }
          }
        }

        ul {
          &.no-left {
            li {
              padding-left: 0px;
            }
          }

          li {
            padding-left: 17px;
          }
        }

        > .inner {
          margin: 0px;
        }
      }
    }

    > .main {

      @include mq($from: tablet) {
        padding-right: 0px;
      }

      > .box.filter {
        margin-bottom: 25px;

        i.fa{
          margin-right: 5px;
        }

        .mobile-wrap{
          float: right;
        }



        .total-items{
          float: left;
        }

        .sort-wrap{
          float: right;
        }

        @include mq($until: mobile) {
          //xs
          .mobile-wrap{
            float: left;
          }
        }



        @include mq($until: mobile) {
          .subcat-wrap{
            width: 40%;
            float: left;
          }

          .filter-wrap{
            width: 30%;
            text-align: center;
            float: left;
          }

          .sort-wrap{
            width: 30%;
            text-align: right;
            float: left;

            button{
              padding: 0 6px;
            }

          }

        }

      }
    }
  }
}





.boxPageSplit {
  .box.page {
    border-bottom: 1px solid #E3E3E3;

    + .box.page {
      border-bottom: 1px solid #E3E3E3;
    }

    &:last-child {
      border-bottom: none !important;
    }
  }
}