.burger {
  position: relative;
  padding: 0;
  background-color: transparent;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 0;
  height: $header-height-mobile;

  // We remove the `outline` here, but later compensate by attaching `:hover`
  // styles to `:focus`.
  &:focus {
    outline: 0;
  }

  // Bars
  .icon-bar {
    display: block;
    width: 20px;
    height: 3px;
    background: $color-black;
    margin-top: 3px;
    position: relative;
    transition: all 500ms ease-in-out;

    &:first-child {
      margin-top: 0;
    }
  }

  @include mq($from: tablet) {
    display: none;
  }

  &.active {
    display: block !important;
  }
}

.menu-mobile-offcanvas {
  .burger {
    position: absolute;
    right: 3px;
    padding: 16px;
    top: 4px;

    &.active{
      .icon-bar {
        &:nth-of-type(1) {
          top: 6px;
          transform: rotate(45deg);
        }

        &:nth-of-type(2) {
          background-color: transparent;
        }

        &:nth-of-type(3) {
          top: -6px;
          transform: rotate(-45deg);
        }
      }
    }
  }
}