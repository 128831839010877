.box.blog {
  margin-top: 25px;

  > .header {


    .title {
      @media (max-width: $screen-sm-min) {
        text-align: left;
      }
    }

    .time {
      display: block;
      color: #D2D1D0;
      font-size: 12px;
      text-transform: uppercase;
    }
  }

  > .inner {
    padding: 0px;

    img {
      max-width: 100%;
      border-radius: 3px;
      overflow: hidden;
      margin: 15px 0px;
    }

    a.more {
      text-transform: uppercase;
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }
  }

  + .box.blog {
    &:before {
      width: 95%;
      margin: auto;
      margin-bottom: 40px;
      height: 1px;
      background: #F4F3F4;
    }
  }
}