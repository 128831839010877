.new-product-list {
  .product-block {
    padding-left: 5px;
    padding-right: 5px;

    @include mq($until: mobile) {
      .product {
        padding-left: 5px;
        padding-right: 5px;
      }

      &:nth-child(odd) {
        padding-right: 0;
      }

      &:nth-child(even) {
        padding-left: 0;
      }
    }

    // This is done to prevent grid from breaking if
    // the heights of element are different
    @include mq($until: tablet) {
      &:nth-child(2n+1){
        clear:left
      }
    }

    @include mq($from: tablet) {
      &:nth-child(3n+1){
        clear: left;
        #wishlist-items & {
          clear: none;
        }
      }
    }
  }

  // Reset clear if products are in slider block
  .product-list-block {
    .product-block {
      @include mq($until: mobile) {
        &:nth-child(odd) {
          padding-right: 5px;
        }

        &:nth-child(even) {
          padding-left: 5px;
        }
        padding-left: 0 !important;
      }

      @include mq($until: tablet) {
        &:nth-child(2n+1){
          clear: none;
        }
      }

      @include mq($from: tablet) {
        &:nth-child(3n+1){
          clear: none;
        }
      }
    }
  }

  .product {
      position: relative;
      margin-bottom: 20px;
      padding: 10px 10px 0 10px;

      @include mq($from: mobile) {
          border: 1px solid transparent;
      }

      .wishlist-add {
          display: none;
          position: absolute;
          right: 5px;
          height: 50px;
          width: 50px;
          padding-top: 10px;
          padding-left: 15px;
          z-index: 1;

          @include mq($until: desktop) {
              display: block;
          }
      }

      .image {
          position: relative;

          img {
              text-indent: -9999px;
          }

          @include mq($from: wide) {
              background-color: #f5f5f5;
              min-height: 357px;
          }

          .img-responsive {
              width: 100%;
          }

          .hover {
              position: absolute;
              top: 0;
              left: 0;
              display: none;
          }

          .labels-top, .labels {
              .label {
                  float: left;
                  position: relative;
                  display: inline-block;
                  padding: 4px 9px;
                  min-width: 49px;
                  margin-left: 7px;
                  margin-bottom: 7px;
                  font-size: 10px;
                  line-height: 13px;
                  font-weight: normal;
                  border-radius: 2px;
                  //background-color: transparent;

                  .bg {
                      display: block;
                      position: absolute;
                      left: 0;
                      right: 0;
                      top: 0;
                      bottom: 0;
                      border-radius: 2px;
                      background-color: #fbf9f9;
                      z-index: 1;
                      opacity: .9;
                  }
                  .text {
                      position: relative;
                      color: #000000;
                      z-index: 2;
                  }

                  &.label-danger {
                      .bg {
                          background-color: #f95e65;
                      }
                      .text {
                          color: #ffffff;
                      }
                  }
                  @include mq($until: mobile) {
                      //font-size: 19px;
                      //line-height: 22px;
                  }
              }
          }

          .labels-top {
              position: absolute;
              top: 0px;
              left: 0px;
              right: 7px;

              .label {
                  margin-top: 7px;
                  margin-bottom: 0px;
              }
          }
          .labels {
              position: absolute;
              bottom: 0px;
              left: 0px;
              right: 7px;
          }
      }

    .info {
      padding-top: 7px;
      padding-bottom: 10px;
      min-height: 77px;
    }

    .left{
      float:left;
      width:100%;
      padding-right: 65px;

      @include mq($until: tablet) {
        padding-right: 0;
        float: none;
      }
    }

    .right{
      width: 100px;
      right: 10px;
      position: absolute;

      @include mq($until: tablet) {
        position: relative;
        width: 100%;
        right: 0;
        padding-top: 4px;
      }
    }

    .brand {
      position: relative;
      overflow: hidden;
    }

    .title {
      font-size: 12px;
      font-weight: normal;
      padding-top: 2px;
      white-space:nowrap;
      position: relative;
      overflow: hidden;

      a {
        text-decoration: none;
      }
    }

    .price {
      .discounted {
        color: $color-red;
      }

      @include mq($until: tablet) {
        font-size: 12px;

        s {
          padding-right: 5px;
        }

        &.text-right {
          text-align: left;
        }
      }
    }

    .one-left,
    .sold-out {
      color: $color-red;
      font-size: 12px;
      padding-top: 2px;
    }

    .size {
      display: none;
      font-size: 12px;

      @include mq($until: desktop) {
        display: none;
      }

      ul {
        margin: 0;

        li {
          font-size: 12px;
          line-height: 20px;
          padding-left: 0;
        }
      }
    }

    .no-touch & {
      @include mq($from: tablet) {
        &:hover {
          border: 1px solid $border-grey;

          .wishlist-add {
            display: block;
          }
          .size {
            display: block;
          }
          .image img.hover {
            display: block;
          }
        }
      }
    }

    //.touch & {
    //  .size {
    //    display: block;
    //  }
    //}
  }
}


@import "list/top";