#cart-empty {
  .box {
    padding: 60px 30px;
    @include mq($until: desktop) {
      padding: 30px 30px;
    }
  }

  .btn {
    margin-right: 7px;
    margin-left: 7px;

    @include mq($until: mobile) {
      display: block;
      width: 100%;
      margin: 0 0 15px 0;
    }
  }

  .title {
    font-size: 24px;
    font-weight: 700;
    color: $color-black;
    text-transform: uppercase;
    margin-bottom: 5px;
    letter-spacing: 1px;
  }

  .sub-text {
    font-size: 14px;
    font-weight: 400;
    color: $color-black;
    margin-bottom: 50px;
  }
}