@media (max-width: 991px) {
  .product-filter-left-filters {
    margin-top: 30px;
  }
}
.product-filter-left-filters,
.filter-products-form {
  .box.list {
    border-bottom: 1px solid $border-grey;
    margin-bottom: 24px;
    padding-bottom: 14px;

    &:first-child {
      border-top: 1px solid $border-grey;
      padding-top: 24px;
      margin-top: 10px;
    }

    .price-div .col-xs-12 {
      padding-bottom: 10px;
    }

    .slimScrollDiv {
      margin-bottom:  8px;
    }

    .collapsing {
      -webkit-transition: none;
      transition: none;
    }

    > .header {
      .title {
        letter-spacing: 0;

        i.filter-margins {
          margin: 0 0 0 3px;
          width: 15px;
        }
      }
    }

    .inner {
      .list-filter {
        &.less {
          height: auto;
          padding-bottom: 6px;
        }

        overflow-x: hidden;
        height: 120px;

        .checkbox {
          &.indent {
            padding-left: 15px;
          }
          &.disabled {
            color: $color-grey;
          }

          label {
            width: 100%;
          }

          &.active,
          &:hover {
            font-weight: bold;
            text-decoration: none;

            label {
              font-weight: bold;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}

.box.list {
  > .header {
    .title {
      font-size: 14px;
    }
  }

  > .inner {
    //.product-filter-more-items {
    //  cursor: pointer;
    //  margin-left: 24px;
    //
    //  &:hover {
    //    font-weight: bold;
    //  }
    //}

    //ul {
    //  li {
    //    float: none;
    //    line-height: 27px;
    //    font-weight: normal;
    //
    //    label {
    //      padding-left: 6px;
    //    }
    //
    //    &.hidden-item {
    //      display: none;
    //    }
    //
    //    &.active,
    //    &:hover {
    //      font-weight: bold;
    //      text-decoration: none;
    //
    //      > a {
    //        font-weight: bold;
    //        text-decoration: none;
    //      }
    //    }
    //
    //    ul {
    //      margin-left: 10px;
    //    }
    //
    //    //input[type='checkbox'] {
    //    //  margin-right: 5px;
    //    //  margin-left: -17px;
    //    //  position: absolute;
    //    //  top: 4px;
    //    //}
    //  }
    //}
  }
}