.paymentMethodList {
  margin-bottom: 30px;

  @include mq($until: desktop) {
    margin-bottom: 0px;
  }

  .listItem {
    padding-top: 7px;
    padding-bottom: 7px;

    .itemTitle,
    .itemRadio {
      cursor: pointer;
    }

    &.active {
      .itemTitle {
        font-weight: 500;
      }
    }

    .itemRadio {
      margin-right: 5px;
    }

    .itemContent {
      &.banks {
        padding: 0 3px;

        .paymentBankList {
          margin-top: 10px;

          .bank {
            margin-bottom: 10px;
            text-align: center;
            cursor: pointer;

            @include mq($from: desktop) {
              &:hover {
                .image {
                  border-color: #b5b5b5;
                  background: $background-light-grey;
                }
              }
            }

            &.active {
              .image {
                border-color: #b5b5b5;
                background: $background-light-grey;
              }
            }

            .image {
              display: table;
              border: 1px solid #f3f3f3;
              height: 70px;
              padding: 15px;

              span {
                display:table-cell;
                vertical-align: middle;
                img {
                  width: 100%;
                }
              }
            }
          }
        }
      }

      &.text {
        padding-left: 20px;
        color: #9a9fa1;

        .description {
          padding-top:7px;
          padding-bottom:10px;
        }
      }
    }
  }
}