.btn-google, .btn-facebook {
  margin-top: 8px;
  padding: 7px 15px;
  border: 1px solid $color-grey !important;
  color: $color-black;
  background-color: #fff;
  border-radius: 4px;
  font-weight: bold;
}
#auth {
  form {
    padding: 0px 30px;

    .or {
      padding: 20px 0px 25px 0px;
      text-align: center;
      color: #ccc;
    }

    .forgot-password {
      color: #000;
      margin-bottom: 14px;
      font-size: 14px;
      padding-top: 3px;
      border-bottom: 2px solid #000;
      &:hover {
        text-decoration: none;
      }
    }

    #remember {
      margin-top: 2px;
      margin-right: 3px;
      margin-bottom: 14px;
      vertical-align: top;
    }
  }

  .box.white-box {
    padding-left: 0px;
    padding-right: 0px;

    @include mq($from: tablet) {
      padding-left: 40px;
      padding-right: 40px;
    }

    > .header {
      margin-bottom: 16px;
      .title {
        display: block;
        margin: 0px 30px;
        padding: 5px 0px 6px 0px;
        font-size: 17px;
        line-height: 20px;
        border-bottom: 1px solid #f3f3f3;
      }
    }
    > .footer {
      //margin-top: 0px;
      padding-top: 0px;
      border-top: none;

      .title {
        margin: 0px 30px 25px 30px;
        font-size: 17px;
        line-height: 31px;
        text-align: left;
        font-weight: bold;
        border-bottom: 1px solid #f3f3f3;
      }
      .registerBtnWrap, .loginBtnWrap {
        a {
          display: block;
          margin: 0px 30px;
          padding: 7px 15px;
          border: 1px solid $color-green;
          color: $color-green;
          border-radius: 4px;
        }
      }
    }
  }

  .btn {
    font-size: 14px;
    text-transform: uppercase;
  }
}

.facebook {
  background-color: #3366CC;
}

.cancel-button {
  margin-top: 15px;

  a {
    font-size: 14px;
    color: #ccc;
    font-weight: normal;
  }
}
.loginOpenWrap {
  padding-top: 8px;

  .wrapOpenBtn {
    margin-top: 0px !important;
  }
}
.loginFormWrap {
  .form-group {
    margin-bottom: 14px;
  }
  .control-label {
    margin-bottom: 4px;
  }
  .form-control {
    height: 38px;
  }
}

.registerFormWrap {
  margin-bottom: 0px;

  form {
    > p {
      margin-bottom: 25px;
    }
  }
  .socialRegWrap {
    .social-title {
      margin-bottom: 0px;
    }
  }
  .social-reg-separator {
    position: relative;
    margin: 25px 0px;
    height: 1px;
    width: 100%;
    background-color: #DCDCDC;

    > span {
      position: absolute;
      top: 50%;
      left: 50%;
      padding: 5px 10px;
      font-size: 14px;
      line-height: 20px;
      background-color: #ffffff;
      transform: translate(-50%, -50%);
    }
  }
  .form-group.checkbox-flex {
    align-items: flex-start;

    input {
      //margin-top: 2px;
      margin-right: 7px;
    }
    label {
      max-width: calc(100% - 22px);
    }
  }
  .actionButtons {
    .btn-success {
      margin-bottom: 0px !important;
    }
  }
}