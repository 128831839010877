h1 {
  display: inline-block;
}

.slider-nav {
  margin-bottom: 0 !important;
  margin-top: -30px;
  text-align: center;

  .mobile-lookbook & {
    margin-top: 0;
  }
}

.mobile-lookbook {
  .slick-slide img {
    display: initial;
  }
}

.slick-current {
  color: black;

  .image-profile-lookbook {
    visibility: visible;
  }
}

.image-profile-lookbook {
  max-width: 55px;
  height: 55px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  border-radius: 30px;
  visibility: hidden;

  @include mq($from: tablet) {
    display: none;
  }
}

.slider-nav .slick-slide {
  cursor: pointer;
  text-align: center;
  color: #9f9f9f;

  &.slick-current {
    color: black;
  }
}



.product-view {
  .slider-for {
    &.col-lg-push-3 {
      left: 13%;
      width:460px;

      img {
        width:100%;
        height: 614px;
      }
    }

    @include mq($until: wide) {
      &.col-lg-push-3 {
        left: 0;
        width:100%;

        img {
          width:100%;
          height: auto;
          padding-bottom:5px;
        }
      }
    }

    .slick-arrow {
      top: 50%;
      z-index: 1100;
      width: 50px;
      height: 50px;

      &.slick-prev {
        left: 15px;
        &:before {
          color: #b2b1b1;
        }
      }
      &.slick-next {
        right: 15px;
        &:before {
          color: #b2b1b1;
        }
      }
    }
  }

  .slick-dots {
    position:absolute;
    bottom:15px;
    right:15px;
    width:auto;

    li {
      margin:0;
      width:12px;


      button:before {
        font-size:26px;
        width:12px;
        color:#767473;
      }
    }
  }

  .col-lg-pull-9 {
    right: 82%;
    width:13%;

    .slider-nav {
      margin-top: 0;

      .slick-slide {
        span {
          display: table;
          border: 1px solid #ffffff;
          border-radius: 3px;
        }

        &.slick-current {
          span {
            border: 1px solid #9f9f9f;
          }
        }
      }

      img {
        height: 80px;
        width:  60px;
      }
    }

    @include mq($until: wide) {
        right: 0;
        width:100%;

      .slider-nav{
        span {
          margin-right:5px;
        }

        img {
          padding-top:0;
          padding-bottom:0;

          height: 100px;
          width: 75px;
        }
      }
    }
  }
}

@include mq($until: mobile) {
  .product-view-main {
    padding:0;

    > .row {
      margin:0;
    }

    .box.product-view {
      padding:0!important;
    }

    .center {
      padding-left:30px!important;
      padding-right:30px!important;
    }
  }
}