.box.boutiques-list {

  &.big {
    .inner {
      ul {
        li {
          height: 365px;
          padding-top: 90px;
        }
      }
    }
  }

  .inner {
    > div {
      margin-bottom: 30px;

      > div {
        + div {
          margin-top: 15px;
        }
      }

    }

    ul {
      border-radius: 3px;
      overflow: hidden;

      //@include mq($from: mobile, $until: tablet) {
      //  height: 365px;
      //}
      .no-touch & {
        &:hover {
          li {
            .on-hover {
              display: block;
            }

            &:first-child {
              background: black !important;

              .brand-name, .comment, .rating {
                color: white;
              }

              .brand-logo {
                .black {
                  display: none;
                }

                .white {
                  display: block !important;
                }
              }
            }
          }
        }
      }

      li {
        width: 63%;
        position: relative;
        height: 240px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        text-align: center;
        padding: 40px 30px 0px 30px;

        @include mq($until: mobile) {
          height: initial;
          padding: 30px;
        }

        &:nth-child(1) {
          width: 37%;

          @include mq($until: mobile) {
            width: 100%;
          }

          @include mq($from: tablet, $until: desktop) {
            width: 40%;
          }

          @include mq($from: mobile, $until: tablet) {
            width: 50%;
          }
        }

        &:nth-child(2) {
          @include mq($until: mobile) {
            width: 100%;
          }
          @include mq($from: tablet, $until: desktop) {
            width: 60%;
          }
          @include mq($from: mobile, $until: tablet) {
            width: 50%;
          }
        }

        .brand-logo {
          height: 70px;
          width: 100%;
          position: relative;

          @include mq($until: mobile) {
            #homepage & {
              width: 50%;
              float: right;
            }
          }

          img {
            position: absolute;
            max-height: 100%;
            max-width: 100%;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            margin: auto;
          }
        }

        .brand-name {
          text-transform: uppercase;
          padding-top: 40px;
          line-height: 16px;

          @include mq($until: mobile) {
            padding-top: 20px;
          }
        }

        .location {
          color: $color-grey;
          line-height: 19px;
          font-size: 13px;

          a {
            color: inherit;
          }
        }

        .rating {
          #homepage & {
            display: none;
          }
        }

        .stars {
          margin: 5px 0px;
          font-size: 12px;

          > i {
            margin-left: 2px;
          }
        }

        .on-hover {
          position: absolute;
          top: 10px;
          right: 10px;
          text-align: right;
          display: none;

          &.favorited {
            display: block;
          }
        }

        .labels {
          position: absolute;
          top: 10px;
          left: 10px;
          text-align: left;
        }
      }
    }
  }
}


#homepage, #menu-content {
  .box.boutiques-list {
    .comment, .stars {
      display: none;
    }
  }
}