.box.product-list.popup-cart-list {
  margin-bottom: 0;
  padding: 0;
  font-size: 12px;


  .cont {
    .inner {
      border-bottom: 1px solid #ececec;
      margin: 0 -15px;
      padding: 0 5px;
      background-color: #ffffff;

      > div {
        margin-bottom: 15px;
      }
    }
  }


  .inner {
    border-bottom: 1px solid #ececec;
    margin: 0 -15px;
    padding: 0 15px;
    background-color: #ffffff;

    > div {
      margin-bottom: 0;

      .product {
        border-bottom: 1px solid #ececec;
        padding: 15px 0 15px 15px!important;
        margin: 0;

        a {
          z-index:1;
        }

        .on-hover {
          display: none;
        }

        .specifications {
          ul {
            li {
              float: none;
              line-height: 20px;

              .delete {
                border-bottom: 1px solid black;
                font-size: 12px;

                &:hover,
                &:active {
                  text-decoration: none;
                }
              }
            }
          }
        }

        .image {
          width: 70px;
          height: 94px;
          float: left;
          margin-right: 10px;
          margin-left: 10px;
        }

        .info {
          float: right;
          margin-top: 0;
        }

        &:last-child {
          border-bottom: none;
          margin-bottom: 0;
          padding-bottom: 0;

          .soldout {
            height: calc( 100% );
          }
        }
      }
    }
  }
}