.container-fluid.divider {
    max-width: 1170px !important;
    height: 0 !important;
    border-bottom: 1px solid $border-grey;
}

header {
    height: $header-height;
    //border-bottom: 1px solid $border-grey;
    position: relative;
    background-color: white;
    z-index: 1200;

    @media (max-width: 950px) {
        height: $header-height-mobile;
    }

    .menu-block {
        @media (min-width: 951px) {
            margin-left: 24px;
        }
        @include mq($from: desktop) {
            position: relative;
            z-index: 1;
            max-width: 180px;
        }
        @include mq($from: wide) {
            max-width: 350px;
            width: 100%;
        }
        @media (min-width: 951px) {
            &.menu-block-burger {
                display: none;
            }
        }
        @media (max-width: 950px) {
            width: 44px;
            padding-left: 12px;
            padding-right: 12px;
            &.menu-block-top {
                display: none;
            }
        }

        @import "header/menu";
    }
    .col-md-7 {
        @media (min-width: 992px) {
            width: 51.333333%;
        }
    }

    .logo-block {
        @include mq($from: tablet) {
            //position: absolute;
            width: 207px;
            //left: 50%;
            //margin-left: -100px;
            z-index: 1;
        }

        @media (max-width: 950px) {
            width: 90px;
            padding-left: 0;
            padding-right: 0;
        }

        .logo {
            display: table;
            height: $header-height;
            margin: auto;

            @media (max-width: 950px) {
                height: $header-height-mobile;
            }

            a {
                text-decoration: none;
                display:table-cell;
                vertical-align: middle;
            }

            img {
                max-width: 170px;
                width: 100%;
                display: block;
                margin: auto;

                @media (min-width: 951px) {
                    max-width: 175px;
                    margin-top: 15px;
                }
            }

            span {
                font-size: 10px;
                font-weight: 300;
                color: $color-grey;
                text-transform: none;
                padding-top: 9px;
                display: block;
                letter-spacing: 0.17px;

                @media (max-width: 950px) {
                    display: none;
                }
            }
        }
    }

    &.small {
        .container-fluid {
            position: relative;
        }

        .logo-block {
            @include mq($from: tablet) {
                left: 0;
                margin-left: 0;
            }

            @include mq($until: tablet) {
                padding-left: 15px;
                padding-right: 0;
            }
        }

        .help {
            height: $header-height;
            display: table;

            @include mq($until: tablet) {
                height: $header-height-mobile;
            }

            span {
                display: table-cell;
                vertical-align: middle;

                @include mq($from: tablet) {
                    font-size: 19px;
                    font-weight: 300;

                    i {
                        padding-right: 10px;
                    }
                }
            }
        }
    }

    .user-block {
        @media (max-width: 950px) {
            width: auto;
            float: right;
        }
        @include mq($until: desktop) {
            position: initial;
        }

        a:hover {
            text-decoration: none;
        }

        .btn-default:active,
        .btn-default.active,
        .open > .btn-default.dropdown-toggle {
            color: $color-black;
            background-color: transparent !important;
            box-shadow: none !important;
        }

        button.btn {
            border: none;
            border-radius: 0;
            background-color: transparent;
            height: $header-height;
            padding: 8px 15px 0 15px;
            opacity: 1;
            letter-spacing: 0.3px;
            color: $color-black;

            &.shopping-bag {
                padding: 8px 0 0 19px;
                margin-right: -2px;
            }

            @media (max-width: 950px) {
                height: $header-height-mobile;
                padding-left: 15px;
                padding-right: 0;
                padding-top: 4px;
            }

            &:hover,
            &:active,
            &:focus,
            &.active {
                color: $color-black;
                background-color: transparent !important;
                box-shadow: none !important;
            }

            &:hover,
            &.active {
                @include mq($from: tablet) {
                    > span {
                        padding-bottom: 4px;
                        border-bottom: 3px solid $color-black;
                    }
                }
            }
        }

        .dropdown-menu {
            top: 95px;

            @include mq($until: tablet) {
                top: 47px;
            }
        }

        @import "header/search";
        @import "header/account";
        @import "header/wishlist";
    }
}

@import "header/shopping-bag";



@media (min-width:900px) {
    .dropdown:hover .dropdown-menu {
        display: block;

    }
    .dropdown .dropdown-menu{
        display: none;
    }

}

.col-no-padding {
    padding-left: 0;
    padding-right: 0;
}

.navbar-default{
    background: none;
    padding: 0;
    border: none;
    background-color: white;
    margin-bottom: 0;

    @media (max-width: 950px) {
        display: none;
    }

    .nav {
        float: none;
        margin: auto;
        display: table;
        max-width: 1170px;
        width: 100%;

        > li {
            &.active,
            &.active:hover,
            &:hover {
                a {
                    background-color: transparent;
                    &:after {
                        border-color: $color-black;
                    }
                }
            }

            > a {
                padding: 15px 11px 19px 11px;
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;
                letter-spacing: 0.7px;
                color: $color-black;
                //text-transform: uppercase;

                .bold,
                b {
                    font-weight: 500;
                }
                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    width: calc(100% - 22px);
                    height: 0px;
                    left: 11px;
                    bottom: 0px;
                    border-bottom: 1px solid transparent;
                }
            }

            @include mq($from: tablet, $until: wide) {
                > a {
                    padding-left: 15px;
                    padding-right: 15px;
                }
            }

            @include mq($from: wide) {
                &:first-child a {
                    //padding-left: 0px;
                    &:after {
                        width: calc(100% - 15px);
                        left: 4px;
                    }
                }
            }

            > .dropdown-menu {
                border-top: 1px solid $border-grey;
                padding: 10px 0;
                min-width: 200px;
                z-index: 1101;

                &.columns-2 {
                    min-width: 400px;

                    @media (min-width: 768px) and (max-width:1023px) {
                        margin-left: -100px;
                    }
                }

                &.columns-3 {
                    min-width: 600px;

                    @media (min-width: 768px) and (max-width:1023px) {
                        margin-left: -300px;
                    }
                }

                &.columns-4 {
                    min-width: 800px;

                    @media (min-width: 768px) and (max-width:1023px) {
                        margin-left: -300px;
                    }
                }

                ul {
                    list-style: none;

                    li {
                        &.active {
                            > a {
                                text-decoration: underline;
                                color: $color-black;
                            }
                        }

                        &.no-url:hover a {
                            text-decoration: none !important;
                            cursor: default !important;
                        }

                        a {
                            padding: 5px 15px;
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 1.428571429;
                            display: block;
                            min-height: 28px;

                            .bold, b {
                                font-weight: 500;
                            }
                            &:hover {
                                text-decoration: underline;
                                color: $color-black;
                            }
                        }
                    }
                }
            }
            .menu-sub-wrap {
                padding-left: 10px;
            }
        }
    }
}

@media (max-width: 950px) {
    .navbar-default-divider {
        display: none;
    }
    .burger {
        display: inline-block !important;
    }
}
