.address-button {
  border: 1px solid #ececec !important;
  min-width: 155px !important;
  margin: 0 15px 30px 0;
  color: #2b363a;
  font-size: 13px!important;
}

.address-block form.pull-left {
  width: 47%;
}

.addresses-popup {
  display: none;
  position: absolute;
  top: 0px;
  background: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  width: 100%;
  height: 100%;


  .close-btn {
    font-size: 20px;
    margin-top: 20px;
    text-align: right;

    a {
      text-decoration: none;
    }
  }

  .content {
    background: #ffffff;
    padding: 60px;
    margin: 50px 90px;
    max-width: 750px;

    @include mq($until: tablet) {
      margin: 50px 5px;
    }

    @include mq($until: mobile) {
      padding: 30px 10px;
    }

    ul {
      padding-left: 0px;

      li {
        list-style: none;


        .btn {
          float: right;
          margin: 0 5px 5px;

          @include mq($until: mobile) {
            width: 100%;
            margin-top: 15px;
          }
        }
      }
    }
  }
}