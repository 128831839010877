.membershop-bar {
  height: 37px;
  line-height: 37px;
  font-size: 12px;
  font-weight: 500;

  > span {
    a {
      color:white;

      &:hover {
        text-decoration: none;
        border-bottom:1px solid #FFF;
      }
      i {
        padding-left: 3px;
      }
    }
  }
}