.view-more {
  border-bottom: 2px solid #000;
  text-transform: uppercase;
  font-size: 12px;
  vertical-align: middle;
  display: inline-block;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    border-bottom-width: 3px;
    margin-bottom: -1px;
  }
}