#minicart {
  display: none;
  position: absolute;
  top: 100px;
  right: 0;
  background: #f5f5f5;
  z-index: 1010;
  width: 330px;
  box-shadow: 0 2px 3px 1px #ccc;
  padding:0 15px 18px 15px;
  line-height: 17px !important;

  @media (max-width: 950px) {
    display: none !important;
  }

  &:after, &:before {
    bottom: 100%;
    left: 83%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: #ffffff;
    border-width: 15px;
    margin-left: -15px;
  }
  &:before {
    border-color: rgba(194, 225, 245, 0);
    border-bottom-color: #ececec;
    border-width: 16px;
    margin-left: -16px;
  }

  .loading {
    margin-top: 15px;
  }

  .btn-view-bag {
    font-size: 14px;
    display: block;
    padding: 12px 16px;
  }

  .total {
    text-align: right;
    margin: 10px 0;
    line-height: 20px;
  }

  .items {
    background-color: #fff;

    .item {
      padding: 20px 30px;
      border-bottom: 1px solid #e4e4e4;
      text-align: left;

      .image {
        width: 66px;
        height: 88px;
        float: left;
        background-size: cover;

        img {
          width:100%;
        }
      }

      .info {
        float: left;
        width: calc(100% - 66px);
        padding-left: 15px;
        font-size: 12px;

        .brand-name,
        .title {
          font-weight: 500;
        }

        .specifications {
          margin-top: 13px;

          .block {
            height: auto;
          }
        }

        .prices {
          float: right;
          font-size: 12px;
          font-weight: bolder;
          text-align: right;

          .price {
            color: $color-red;
          }

          .old-price {
            text-decoration: line-through;
          }

          .price,
          .old-price,
          .normal-price {
            display: block;
          }
        }
      }
    }
  }
}