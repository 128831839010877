.payment-type-icons {
  img {
    width: 36px;
    height: 23px;

    &.border {
      border: 1px solid #cccccc;
    }
    margin-right: 15px;
  }
}

footer {
  .align-middle {
    @include mq($from: tablet) {
      display: table-cell;
      vertical-align: middle;
    }

    &.one-cart-page {
      @include mq($until: tablet) {
        display: table-cell;
        vertical-align: middle;
      }
    }

  }

  .display-footer-table {
    @include mq($from: tablet) {
      height: 70px;
      display: table;
    }

    &.one-cart-height {
      @include mq($until: tablet) {
        height: 45px;
        display: table!important;
      }
    }
  }

  &.cart {
    background: $background-light-grey;
    padding:0;

    @include mq($from: tablet) {
      background: #fff;
    }

    .container-fluid {
      padding-left: 15px;
      padding-right: 15px;
      max-width: 1060px;

      .copyright {
        @include mq($until: tablet) {
          padding-top:30px;
          padding-bottom:30px;
        }
      }
    }
  }
}

#cart_page {
  margin-bottom: 30px;

  .container-fluid {
    max-width:1060px;
    padding-left: 15px;
    padding-right: 15px;
  }

  @include mq($until: tablet) {
    margin-bottom: 0px;
  }


  .padding-left-null {
    padding-left: 0;
  }

  .mw-260 {
    @include mq($from: desktop) {
      max-width: 290px; // because padding 2x15 from div
    }
  }
  .mw-730 {
    @include mq($from: desktop) {
      max-width: 775px; // because padding 2x15 from div
    }
  }
  .mw-810 {
    @include mq($from: desktop) {
      max-width: 810px; // because padding 2x15 from div
    }
  }
  .mw-870 {
    @include mq($from: desktop) {
      max-width: 870px; // because padding 2x15 from div
    }
  }

  @include mq($until: mobile) {
    margin-bottom: 0px;
  }

  .box.white-box > .header > .title, .single-order .box.white-box > .header > .title {
    padding-bottom: 5px;
    font-weight: 500;
    line-height: 50px;
    letter-spacing: inherit;

    &.cart-done {
      margin: 0;
      padding: 0px 0px 6px 0px;

      .text-success {
        color: #5C914F;
      }
      span {
        font-size: 24px;

        @include mq($until: desktop) {
          font-size: 18px; // 16
        }
      }
      i {
        font-size: 28px;

        @include mq($until: desktop) {
          font-size: 18px; // 15
        }
      }
    }
  }
  .box.white-box.without-registration {
    padding: 15px 30px;

    .row {
      margin-left: -7px;
      margin-right: -7px;
    }
    .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
      padding-left: 7px;
      padding-right: 7px;
    }
    .fake-input-wrp {
      margin: 0px;
      width: 100%;
      height: 34px;
      padding: 6px 0px;
      font-size: 14px;
      line-height: 1.428571429;
      color: #000;
    }
    > .header {
      margin-bottom: 17px;
    }
  }

  .without-padding {
    padding: 0;
    margin: 0;

    &.margin-top-15 {
      margin-top: 15px;
    }
  }


  .pay-blade-div {
    .box.white-box {
      margin: 30px 0px 0px 0px;

      &.cart-box {
        @include mq($until: desktop) {
          margin: 10px 0px 10px 0px;
        }
      }
    }

    @include mq($from: desktop) {
      padding-right: 39px;
    }
  }

  .container-fluid {
    .shipping-info {
      @include mq($from: desktop) {
        padding-left: 55px;
      }
    }
  }
  .box.white-box {
    @include mq($from: desktop) {
      //max-width: 730px;
    }
  }

  .box.white-box.empty {
    @include mq($from: desktop) {
      max-width: 1030px;
    }
  }

  .box.product-list .inner > div .product .title {
    padding-right: 20px;
  }

  @include mq($until: tablet) {
    .box.product-list .inner > div .product .specifications {
      margin-bottom: 5px;
    }
  }

  @include mq($until: tablet) {
    &.cart_page_login #cart_summary_box {
      display: none;
    }
  }

  @include mq($until: tablet) {
  &.cart_page_addressForm {
    .box.white-box {
      padding: 15px;

      .header > .title {
        @include mq($until: tablet) {
          font-size: 18px
        }
      }

      .header > .title.cart-title {
        @include mq($until: tablet) {
          font-size: 18px
        }
      }
    }
    }
  }

  .inner-box {
    padding: 26px;

    &.cart-box {
      padding:18px;
    }
  }

  .mobile-cart-info {
    background-color: white;
    padding: 10px 15px 10px 20px;
    margin: -1px -15px 0 -15px;
  }

  .text-near-image {
    .title > * {
      font-size: 12px;
    }

    .specifications li {
      font-size: 11px;
    }

    .delete {
      @include mq($until: tablet) {
        top: 0;
        right: -25px;

        .fa {
          font-size: 22px;
          color: black;
        }
      }
    }
  }

  .input-group {
    input {
      width: 97%;
      border-radius: 3px;
    }
    button {
      margin-left: 0;
      border-bottom-left-radius: 2px;
      border-top-left-radius: 2px;
    }

    #apply_coupon {
      &.active {
        background-color: $gray-base;
        color: #fff;
      }
    }
  }

  #cart_summary_box {
    @include mq($until: desktop) {
      margin-top:0;
      margin-bottom:0;
    }

    .pd-b-30 {
      padding-top:5px;
    }

    &.sticky {
      top: 0;
      z-index: 1015;
    }

    .payment-more {
      width: 36px;
      font-size: 9px;
      border: 1px solid #bdbdbd;
      text-align: center;
      height: 23px;
      color: #b3b3b3;
      max-width: 36px;
      background-color: $background-light-grey;
      font-weight: 500;
      line-height: 22px;
      text-transform: uppercase;
    }

    .table {
      display: table;
      margin: 0;

      .col {
        display: table-cell;
        vertical-align: middle;
      }
    }

    &.ghost {
      opacity: 0.2;
    }

    button#actualPay {
      &.active {
        .active-hidden {
          display:none;
        }
      }
    }

    .header {
      margin-bottom: 0;

      .title {
        a {
          font-size: 13px;
          text-transform: initial;
          position: absolute;
          right: 0;
          bottom: 5px;
          color: #9a9fa1;
          font-weight: 400;
          letter-spacing: 0px;
          max-width: 100px;
          line-height: 20px;
          height: 50px;
          display: table;
          text-align: right;

          span {
            display: table-cell;
            vertical-align: middle;
          }
        }
      }
    }

    #cart_items {
      padding-top: 0;
      overflow: hidden;
      border-bottom: 1px solid #f3f3f3;
      margin-bottom: 15px;

      .inner-parent {
        float: left;
        @include mq($from: desktop) {
          max-width: 130px;
        }

        padding-right: 0;
        font-size: 12px;
      }

      .inner {
        border: none;
        padding-top: 0;
        padding-bottom: 0;

        > div {
          margin-bottom: 0;
        }

        .product {
          font-size: 13px;
          font-weight: normal;

          &:not(:last-child) {
            border-bottom: 1px solid #f3f3f3;
          }

          a, a:hover {
            text-decoration: none;
            cursor: default;
          }

          a {
            display: block;

            @include mq($until: desktop) {
              float: none;
            }

            @include mq($from: desktop) {
              float: left;
            }

            padding:0;
          }

          .info {
            float: none;

            .price {
              padding-bottom: 5px;
            }
          }

          .normal-price {
            font-weight: bolder;
          }

          .title {
            padding-bottom: 5px;

            a {
              padding-bottom: 5px;
            }
          }

          .specifications {
            margin: 0;
            padding:0;

            ul li {
              line-height: 16px;
            }
          }
        }
      }
    }

    #buy_rules {
      a {
        color: #777777;
        text-decoration: underline;
      }
    }

    .button-checkbox-flex {
      display: flex;
      flex-direction: row;

      input {
        margin-top: 3px;
        margin-right: 4px;
      }
    }
  }

  .highlighted {
    background-color: #ebebeb;
    padding-right: 15px;
    margin-top: -5px;
  }

  .cart-item {
    padding-left: 15px;

    .title {
      a:hover {
        text-decoration: underline;
      }
    }

    .price-padding {
      padding-right: 0;
      padding-left: 0;
    }


    .quantity-selection {
      margin-top: -8px;
      margin-left: 10px;

      .select2-container {
        //Yes this is select2 hacking but quick decisions :(
        background-color: $background-light-grey;
        border-radius: 4px;
        min-width: 40px;
      }
    }

    .image-container {
      @include mq($until: tablet) {
        width: 35%;
        overflow: hidden;
        padding-bottom: 15px;
        float: left;
      }

      img {
        @include mq($until: tablet) {
          max-width: 100%;
        }
      }

      .image {
        @include mq($until: tablet) {
          margin: 0 auto;
        }
      }
    }

    .text-near-image {
      @include mq($until: tablet) {
        width: 65%;
      }
    }

    .price-and-quantity {
      @include mq($from: desktop) {
        padding:0;
      }

      &.mobile {
        margin-bottom: 10px;

        .quantity-selection {
          margin-left: 0;
        }
      }

      .padding-quantity-null {
        padding:0;
      }

      .soldout {
        @include mq($until: tablet) {
          text-align: left;
        }
        @include mq($from: tablet) {
          text-align: right;
        }
      }
    }
  }

  @import "../components/cart/login";
  @import "../components/cart/empty";

  .footer {

    &.margin-t-25 {
      @include mq($from: desktop) {
        margin-top: 25px;
      }
    }

    .margin-t-10 {
      @include mq($until: desktop) {
        margin-top: 10px;
      }
    }

    .shipping-and-return-cols {
      background-color:white;
      height:70px;

      .col1 {
        border-right:2px solid #f3f3f3;
      }
      .col2 {
        border-left:2px solid #f3f3f3;
      }

      @include mq($until: desktop) {
        height:86px;
        padding-top:15px;

        border-bottom:1px solid #f3f3f3;
        padding-left:15px;
        padding-right:15px;

        .col1,
        .col2 {
          border:0;
        }
      }

      .block {
        display:table;
        height:70px;
        width:100%;

        @include mq($until: desktop) {
          height:30px;
        }

        span {
          display:table-cell;
          vertical-align: middle;
          padding-left:10px;

          @include mq($until: desktop) {
            padding-left:0;
          }
        }

        img {
          height: 30px;

          @include mq($until: desktop) {
            float:right;
            height:14px;
          }
        }
      }
    }
  }
  
  &.cart-done {
    h4 {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
    }
    .header {
      margin-bottom: 15px;

      .title {
        padding: 15px 0;
        margin-bottom: 15px;
        border-bottom: 1px solid #f3f3f3;
      }
    }
    .main-order-info-wrp {
      display: flex;
      flex-direction: column;

      .list {
        margin-bottom: 15px;

        .item {
          display: flex;
          gap: 25px;
          padding-bottom: 15px;
          overflow: hidden;

          .title {
            flex-grow: 0;
            flex-shrink: 0;
            width: 150px;
          }

          .value {}

          @include mq($until: tablet) {
            .title, .value {}
          }
        }
        .cart-done-separator {
          margin: 12px 0px;
          border: 1px solid #DCDCDC;
        }
      }
      .hasShippingMethodInfoCartDone {
        .shippingMethodInfo {
          position: relative;
          width: 100%;
          right: 0;
          bottom: 0;
        }
      }

      @media (min-width: 992px) {
        flex-direction: row;
        align-items: flex-start;
        gap: 15px;

        .list {
          flex-grow: 1;

          .item {
          }
        }
        .hasShippingMethodInfoCartDone {
          flex-shrink: 0;
          width: 210px;
        }
      }
    }

    .logo-box {
      margin-top: 25px;

      .logo {
        width: 125px;
        display: block;
        margin-right: 30px;
      }

      .fb-like {
        float: right;
      }

      @include mq($until: tablet) {
        .logo {
          float: left !important;
          width: 110px;
          margin-top: 5px;
          margin-bottom: 10px;
        }
      }
    }

    .links {
      margin-bottom: 40px;

      .cart-done_button {
        height: 46px;
        width: 200px;

        @include mq($until: desktop) {
          width: 100%;
          height: 36px;
        }
      }

      a {
        display: block;
        @include mq($until: tablet) {
          width: 100%;
        }
        @include mq($from: tablet) {
          float: left;
        }
      }

      button {
        width: 100%;
      }

      @include mq($from: tablet) {
        margin-top: 50px;

        button {
          width: auto;
        }
      }
    }

    .image-box {
      padding-bottom: 15px;

      .image {
        background-size: cover;
        background-position: center center;
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
      }
    }
  }
}

#couponbox {
  @include mq($until: tablet) {
    margin-top: -9px;
    padding: 15px 30px;
  }
}

@include mq($until: mobile) {
  #cart_page.cart_page_addressForm {

    .shipping-and-return-cols {
      display: none;
    }

    .possiblePaymentMethods {
      display: none;
    }
  }
}

.cart-step-1 > .row > .col-md-9 > .box.white-box {
  padding: 0 15px;
}

@import "cart-checkout";