.single-order {
  .btn {
    font-weight: bold;
  }
}

#cart_page, .single-order {

  @include mq($until: mobile) {
    > .container-fluid {
      padding-left: 0px;
      padding-right: 0px;
    }

    .control-label.extra-margin {
      margin-top: 15px;
    }
  }

  .btn {
    font-size: 14px;
    //min-width: 184px;

    &.address-button {
      @include mq($until: mobile) {
        font-size: 13px !important;
        width: 47%;
        min-width: 0px !important;

        &.add-address-btn {
          width: 100%;
        }
      }
    }
  }

  .auth-form {
    padding-top: 15px;
    padding-bottom: 15px;

    .or {
      padding: 15px 0px;
      text-align: center;
      color: #ccc;
    }

    #remember {
      vertical-align: top;
    }

    .footer {
      width: 110%;
      margin-left: -5%;
      border-top: 1px solid #F3F3F3;
      padding-top: 35px;
      margin-top: 35px;
      text-align: center;
      text-transform: uppercase;
      padding-bottom: 15px;

      a {
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }

  .final_row {

    .footer {
      background: #F3F3F3 !important;
      padding: 0px 30px !important;

      .discount {
        background: #EBEBEB;
        margin-left: -15px;
        padding: 10px 0px 10px 30px;

        @include mq($until: desktop) {
          margin-right: -15px;
        }

        @include mq($until: mobile) {
          margin-bottom: 30px;
          margin-right: -15px;
        }

        .white {
          width: 58%;
          margin-right: 12%;
          float: left;

          @include mq($until: mobile) {
            width: 88%;
          }
        }

        button {
          min-width: initial;

          @include mq($until: mobile) {
            width: 88%;
            margin-top: 15px;
          }
        }
      }
    }

    .table-holder {
      .table {
        max-width: 184px;
        float: right;
        margin-bottom: 10px;

        @include mq($until: mobile) {
          max-width: 100%;
        }

        tr {
          td {
            padding: 4px;
            vertical-align: middle;
            border-top: 0px;

            &:first-child {
              text-align: left;
            }

            &:last-child {
              font-weight: bold;
            }
          }

          &:last-child {
            td {
              &:last-child {
                font-size: 18px;
              }
            }
          }
        }
      }
    }

    .box.white-box {
      padding: 0px 15px;
      margin-top: 0px;
    }

    .box.product-list.cart-list {
      padding: 0px;
    }

    .btn-lg {
      padding: 15px;
    }
  }

  .box.white-box {
    padding: 15px 30px;
    padding-bottom: 0px;

    &.cart-done-box {

      @include mq($until: tablet) {
        margin: 10px auto;
      }
    }

    > .header {

      > .title {
        font-size: 24px;
        margin: 0px;
        border-bottom: 1px solid #f3f3f3;
        width: 100%;
        padding-bottom: 21px;

        .text-success {
          font-size: 20px;
        }
      }
    }

    .header.title {
      &.register, &.login {
        text-align: center;
        font-size: 24px;
        border-bottom: 1px solid #f3f3f3;
        width: 100%;
        padding-bottom: 13px;
        font-weight: bold;
        color: black;
        text-transform: uppercase;
        margin-right: 26px;
        margin-bottom: 26px;
        letter-spacing: 2px;

        @include mq($until: desktop) {
          margin-bottom: 10px;
        }

      }
    }

    .header.title {
      &.sub-text {
        font-size: 12px;
        border-bottom: 0px;
        width: 100%;
        padding-bottom: 0px;
        font-weight: 400;
        color: black;
        text-transform: none;
        margin-right: 26px;
        margin-bottom: 0px;
        letter-spacing: 2px;
      }
    }
  }

  .box.product-list.cart-list {
    margin-bottom: 0px;
    padding: 0px;

    &.order .inner > div {
      .product.total-info,
      .product {

        margin-top: 0px;

        .info {
          width: calc(100% - 114px) !important;
          //height: 20px;
        }

        .quantity {
          height: 20px;
        }
      }
    }

    .footer {
      background: #EBEBEB;
      margin: 0px -30px;
      padding: 15px 30px;

      @include clearfix;

      &.final_row {
        padding: 0px 15px;
      }

      .select2 {
        margin-bottom: 5px;
      }

      .totals {
        padding-top: 10px;
        text-align: right;

        .coupon {
          text-align: left;
          padding-right: 15px;

          .btn {
            margin-left: 0px;
          }

          .white {
            width: 100%;
          }

          .btn {
            min-width: 0;
          }

          .apply_coupon {
            margin-top: 20px;
            width: auto;
            float: left;
          }
        }

        span {
          text-transform: uppercase;
          font-size: 12px;
          vertical-align: middle;

          @include mq($until: mobile) {
            float: left;
          }
        }

        strong {
          font-weight: bold;
          margin-left: 5px;
          font-size: 20px;
          vertical-align: middle;
        }

        .btn {
          margin-left: 30px;

          @include mq($until: mobile) {
            width: 100%;
            margin-left: 0px;
          }
        }
      }
    }

    .inner {
      margin: 0px;
      padding: 0px 15px;

      > .row {
        border-top: 1px solid $color-line;
        padding-bottom: 30px;
        margin-bottom: 5px;

        @include mq($until: tablet) {
          padding-bottom: 10px;
          margin-bottom: 20px;
        }

      }

      > div {
        margin-bottom: 15px;

        .product {
          padding: 0px;
          padding-bottom: 15px;
          margin-bottom: 0px;

          + .product {
            //border-top: 1px solid $color-line;
            //padding-top: 15px !important;
          }

          &.total-info {
            padding: 0 !important;
            padding-top: 5px !important;
            border: none !important;

            .info {
              width: 93px !important;
            }

            .price {
              padding-right: 15px;
            }
          }

          .on-hover {
            display: none;
          }

          .specifications {
            ul {
              li {
                float: none;
                line-height: 20px;

                .delete {
                  border-bottom: 1px solid $color-black;
                  font-size: 12px;

                  &:hover,
                  &:active {
                    text-decoration: none;
                  }
                }
              }
            }
          }

          .image {
            width: 104px;
            height: 138px;
              float: left;
              margin-right: 10px;
              margin-bottom: 0px;
            //@include mq($from: tablet) {
            //}
          }

          .quantity {
            float: left;
            z-index: 100;
            position: relative;

            @include mq($from: desktop) {
              margin-left: 0px;
            }

            span {
              display: block;
              float: left;
            }

            input {
              float: left;
              margin-left: 10px;
              margin-top: -6px;
              width: 65px;
            }

            i {
              width: 12px;
              height: 12px;
              line-height: 12px;
              position: absolute;
              top: -15px;
              right: -15px;
            }
          }

          @include mq($until: mobile) {
            .right-info {
              > div {
                width: 100%;
                padding-top: 15px;
              }
            }
          }

          .info {
            float: left;

            .price-holder {
              float: none;

              > div {
                float: none;
              }
            }
          }

          &:last-child {
            border-bottom: none;
            margin-bottom: 0px;
            padding-bottom: 0px;
          }
        }
      }
    }
  }
}

.return-wrp {
  .box.product-list.cart-list .inner {
    > div {
      .product {
        &.product-heading {
          margin-top: 15px;
          .info {
            margin-left: 99px;
          }
        }
        &.return-product {
          padding-top: 15px;
          .specifications {
            ul {
              &:after {
                content: none;
              }
            }
          }
        }
      }
      .return-summary {
        margin-top: 10px;
      }
    }
  }
}

#cart_done {
  .inner {
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;

    i {
      display: block;
      color: green;
      font-size: 32px;
      margin: 0px 0px 20px 0px;
    }

    strong {
      text-transform: uppercase;
      font-size: 24px;
      font-weight: bold;
      display: block;
    }

    span {
      display: block;
    }

    .view-more-holder {
      margin-top: 50px;
      display: block;
    }
  }
}

.cart-list {
  .product-heading {
    margin-top: 15px;
    padding: 10px 15px !important;
    font-weight: bold;
    background-color: #E3E3E3;
  }

  .inner > .row {
    border-top: 0 !important;

    .pull-left.text-near-image {
      max-width: calc(100% - 115px);
      padding-left: 15px;

      @include mq($until: tablet) {
        max-width: calc(100% - 80px);
      }
    }
  }

  .inner-list {
    border-top: 0;
    border-bottom: 1px solid #F3F3F3;
    .price {
      padding-right: 15px;
    }
    .quantity {
      padding-right: 20px;
    }
  }
}

//return-form

.return-outer {
  .return-form {
    .return-product-list {
      border-bottom: 1px solid #F3F3F3;
      margin-top: 20px;
      margin-bottom: 20px;
      padding-bottom: 20px;

      .product-name {
        text-transform: uppercase;
        font-size: 14px;
        margin-bottom: 0;
      }

      .brand-name {
        font-size: 12px;
        color: #aaa;
      }

      .product-size {
        font-size: 14px;
      }

      .product {
        .select2 {
          margin-bottom: 10px;
        }

        select {
          margin-bottom: 10px;
        }
      }
    }

    button[type="submit"] {
      padding: 15px 25px;
    }

    .return-type-wrap {
      display: flex;
      align-items: center;

      .return-type-desc {
        .form-group {
          margin-bottom: 0;
        }
      }
    }

    .return-terms {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  .title {
    margin-bottom: 10px;
  }

  .title-desc {
    margin-bottom: 20px;

    p {
      margin-bottom: 0;
    }
  }
}

.product-list .col-xs-12 .pull-right {
  text-align: right
}

.product-list-header {

  .product-heading {
    //padding: 10px 114px !important;
    @media screen and (max-width: 768px) {
      //padding: 10px 15px !important;
    }

    div {
      //display: inline-block;
    }

    .customers-product {
      @media screen and (min-width: 1100px) {
        //padding-left: 120px;
      }
    }

    .quantity {
      //float: right !important;
    }

  }
}

.inner-product-list {
  @media screen and (max-width: 768px) {
    //padding-bottom: 40px!important;
  }

  .image {
    //display: inline-block;
    @media screen and (max-width: 768px) {
      //display: block;
      //margin-bottom: 10px;
    }
  }

  .info {
    //display: inline-block
  }

  .specifications {
    //display: inline-block;
    @media screen and (min-width: 1100px) {
      //padding-left: 120px !important;
    }

    .title{
      color: #aaa;
    }

  }

  .quantity {
    //display: inline-block;
    //float: right !important;
    //padding-right: 140px !important;
    @media screen and (max-width: 768px) {
      //padding-right: 30px !important;
    }
  }

}
