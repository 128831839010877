.box.two-list {

  &.top3 {
    ul {
      li {
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          font-weight: bold;
        }
      }
    }
  }

  ul {
    padding: 0px;
    margin: 0px;
    list-style: none;

    li {
      margin-bottom: 5px;
      float: none;

      span {
        float: right;
        font-weight: normal;
        color: #aaa;

        a {
          color: inherit;
        }
      }

      a {
        color: inherit;
        display: block;
        width: 100%;

        &:hover {
          background: #EAEAEA;
          text-decoration: none;
        }
      }

    }
  }
}

#menu-content {
  .box.two-list {
    > .header .title {
      font-size: 14px;
    }
  }
}