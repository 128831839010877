.mobile-image,
.tablet-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  img {
    display: none;
  }
}

@include mq($until: mobile) {
  .mobile-image {
    height: auto !important;
    background: none !important;
    padding: 0px !important;

    img {
      display: block !important;
      width: 100%;
    }
  }
}

.product.list {
  @include mq($until: wide) {
    .mobile-image {
      height: auto !important;
      background: none !important;
      padding: 0px !important;

      img {
        display: block !important;
        width: 100%;
      }
    }
  }
}