//
// Carousel
// --------------------------------------------------

// Wrapper for the slide container and indicators
.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;

  > .item {
    display: none;
    position: relative;
    overflow: hidden;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    @include transition(.6s ease-in-out left);

    @include mq($from: tablet) {
      border-radius: 5px;
    }

    // Account for jankitude on images
    > img,
    > a > img {
      @include img-responsive;
      line-height: 1;
      width: 100%;
    }

    > img {
      @include mq($until: mobile) {
        height: 100%;
        max-width: none;
        width: 100%;
        margin-left: 0;
      }
    }

    // WebKit CSS3 transforms for supported devices
    @media all and (transform-3d), (-webkit-transform-3d) {
      @include transition-transform(0.6s ease-in-out);
      @include backface-visibility(hidden);
      @include perspective(1000px);

      &.next,
      &.active.right {
        @include translate3d(100%, 0, 0);
        left: 0;
      }
      &.prev,
      &.active.left {
        @include translate3d(-100%, 0, 0);
        left: 0;
      }
      &.next.left,
      &.prev.right,
      &.active {
        @include translate3d(0, 0, 0);
        left: 0;
      }
    }
  }

  > .active,
  > .next,
  > .prev {
    display: block;
  }

  > .active {
    left: 0;
  }

  > .next,
  > .prev {
    position: absolute;
    top: 0;
    width: 100%;
  }

  > .next {
    left: 100%;
  }
  > .prev {
    left: -100%;
  }
  > .next.left,
  > .prev.right {
    left: 0;
  }

  > .active.left {
    left: -100%;
  }
  > .active.right {
    left: 100%;
  }

}

// Left/right controls for nav
// ---------------------------
.controls {
  position: absolute;
  width: 100%;
  top: 50%;
  margin-top: -40px;

  @include mq($until: wide) {
    display: none;
  }

  .carousel-control {
    color: #424242;
    font-size: 35px;
    padding: 15px 15px;
    background-image: none !important;
    text-shadow: none;
    opacity: 1;
    position: relative;
    width: auto;

    &.left {
      float: left;
      @include mq($from: wide) {
        margin-left: -45px;
      }
    }
    &.right {
      float: right;
      @include mq($from: wide) {
        margin-right: -45px;
      }
    }

    // Hover/focus state
    &:hover,
    &:focus {
      outline: 0;
      //color: $carousel-control-color;
      text-decoration: none;
      @include opacity(.8);
    }
  }
}

// Optional indicator pips
//
// Add an unordered list with the following class and add a list item for each
// slide your carousel holds.
.carousel-indicators {
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: 15;
  width: 60%;
  margin-left: -30%;
  padding-left: 0;
  list-style: none;
  text-align: center;

  @include mq($from: tablet) {
    bottom: -40px;
  }
}
.carousel-indicators li {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 1px;
  text-indent: -999px;
  border-radius: 10px;
  cursor: pointer;
  background-color: #d7d7d7;
}
.carousel-indicators .active {
  background-color: #393939;
}


// Optional captions
// -----------------------------
// Hidden by default for smaller viewports
.carousel-caption {
  position: absolute;
  left: 15%;
  right: 15%;
  bottom: 0;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: $carousel-caption-color;
  text-align: center;

  & .btn {
    text-shadow: none; // No shadow for button elements in carousel-caption
  }
}

// Scale up controls for tablets and up
@media screen and (min-width: $screen-sm-min) {

  // Scale up the controls a smidge
  .carousel-control {
    .glyphicon-chevron-left,
    .glyphicon-chevron-right,
    .icon-prev,
    .icon-next {
      width: ($carousel-control-font-size * 1.5);
      height: ($carousel-control-font-size * 1.5);
      margin-top: ($carousel-control-font-size / -2);
      font-size: ($carousel-control-font-size * 1.5);
    }
    .glyphicon-chevron-left,
    .icon-prev {
      margin-left: ($carousel-control-font-size / -2);
    }
    .glyphicon-chevron-right,
    .icon-next {
      margin-right: ($carousel-control-font-size / -2);
    }
  }

  // Show and left align the captions
  .carousel-caption {
    left: 20%;
    right: 20%;
    padding-bottom: 30px;
  }
}

// home page slider
.home-slider-wrap {
  margin-bottom: 25px;
  position: relative;
  margin-left: -15px;
  margin-right: -15px;

  @include mq($from: wider) {
    margin-left: -30px;
    margin-right: -30px;
  }

  .box.carousel {
    padding-bottom: 0;
  }

  .carousel-caption {
    text-align: left;
    left: 0;
    margin-left: 95px;
    padding-bottom: 0;
    margin-bottom: 9%;
    padding-left: 3px;

    @include mq($until: mobile) {
      margin-left: 35px;
    }

    .btn {
      text-transform: uppercase;
      padding-left: 35px;
      padding-right: 35px;
      font-weight: normal;
      font-size: 12px !important;

      @include mq($until: tablet) {
        margin-left: 0px;
        margin-right: 0px;
      }
    }

    @include mq($until: mobile) {
      margin-bottom: 60px;
      padding-bottom: 0;
    }

    h1 {
      text-transform: uppercase;
      margin-bottom: 0;
      font-weight: 900;
      font-size: 38px;
      line-height: 38px;

      @include mq($until: tablet) {
        font-size: 24px;
      }
    }

    .text-italic {
      font-style: italic;
      padding-bottom: 33px;

      @include mq($until: tablet) {
        font-size: 14px;
        padding-bottom: 5px;
      }
    }
  }
}