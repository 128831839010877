.favorite {
  &.product {
    text-shadow: $text-shadow;
    color: white;
    cursor: pointer;
    font-style: normal;

    &:before {
      content: "\f004";
      font-family: FontAwesome;
    }
  }

  @include mq($until: tablet) {
    span {
      display: none;
    }
  }

  &.shop {
    text-transform: uppercase;
    font-size: 14px;
    vertical-align: middle;
    font-weight: normal;
    margin-left: 15px;
    display: inline-block;
    cursor: pointer;
    font-style: normal;

    span:nth-of-type(2) {
      display: none;
    }

    &.favorited {

      span:nth-of-type(1) {
        display: none;
      }

      span:nth-of-type(2) {
        display: inline;
      }
    }

    &:before {
      content: "\f08a";
      font-family: FontAwesome;
      font-size: 19px;
      color: #aaa;
      margin-right: 5px;
    }

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;

      &:before {
        color: $color-red;
      }
    }

    .box.boutiques-list & {
      &:hover {
        color: #94aaaa !important;
      }
    }
  }

  &.favorite-lg {
    font-size: 18px;
  }

  &:hover,
  .active {
    color: $color-red !important;
  }

  &.favorited {
    color: $color-red !important;

    &:before {
      color: inherit;
      content: "\f004";
    }

    &:hover {
      color: #000 !important;

      &:before {
        color: inherit;
        content: "\f08a";
      }
    }
  }
}

.boutiques-list {
  .favorite {
    &:hover,
    .active {
      color: #909090;
    }
  }
}