.separator {
  margin-top: 0;
  margin-bottom: 30px;
  height: 1px;
  background-color: #dddddd;
}

.box.product-view {
  padding-left: 15px;
  padding-right: 15px;

  //@include mq($until: tablet) {
  //  margin-bottom: 0;
  //  padding-bottom: 0;
  //}

  > .inner {
    @include clearfix;
    padding-top: 15px;

    .wishlist-add {
      position: absolute;
      right: -30px;
      top: 11px;
      .fa-spinner {
        &:before {
          color: #ccc;
        }
      }
      .fa-heart {
        &:before {
          content: "\f08a";
          color: #ccc;
        }
      }
      @include mq($from: desktop) {
        &:hover {
          .fa-heart {
            &:before {
              content: "\f004";
              color: #000;
            }
          }
        }
      }
      &.added {
        .fa-heart {
          &:before {
            content: "\f004";
            color: #000;
          }
        }
        @include mq($from: desktop) {
          &:hover {
            .fa-heart:before {
              color: #ccc;
            }
          }
        }
      }

      &.product-top {
        position: absolute;
        top: 0;
        right: 0;
        width: 50px;
        height: 50px;
        padding-top: 15px;
        padding-left: 15px;
        
        z-index: 9999;
        .fa-heart {
          &:before {
            content: "\f004";
            color: #fff;
          }
        }
        &.added {
          .fa-heart {
            &:before {
              color: #000;
            }
          }
        }
      }
    }
    > .left {
      float: left;
      width: 48%;
      position: relative;

      @include clearfix;

      @include mq($until: tablet) {
        width: 100%;
      }

      .labels-top, .labels {
        left: 73px;

        @include mq($until: wide) {
          left: 0;
        }

        .label {
          position: relative;
          display: inline-block;
          margin: 0px 0px 7px 7px;
          padding: 5px 12px;
          min-width: 49px;
          border-radius: 0;
          font-size: 13px;
          line-height: 15px;
          font-weight: normal;
          background-color: transparent;

          .bg {
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            border-radius: 2px;
            background-color: #fbf9f9;
            z-index: 1;
            opacity: .9;
          }
          .text {
            position: relative;
            color: #000000;
            z-index: 2;
          }
          &.label-danger {
            .bg {
              background-color: #f95e65;
            }
            .text {
              color: #ffffff;
            }
          }
        }
      }
      .labels-top {
        position: absolute;
        top: 0px;
        right: 7px;
        z-index: 1;

        .label {
          margin: 7px 0px 0px 7px;
        }
      }
      .labels {
        position: absolute;
        bottom: 0px;
        right: 7px;
        z-index: 1;

        .label {}
        @media screen and (min-width: 641px) and (max-width: 1199px) {
          bottom: 107px;
        }
      }

      .loader {
        position: absolute;
        top: 15px;
        right: 10%;
        z-index: 1;
        color: #b2b1b1;
        font-size: 25px;
      }
    }

    > .center {
      float: left;
      width: 52%;
      height: 100%;
      position: relative;
      padding-left: 30px;
      @include clearfix;

      @include mq($until: wide) {
        width: 52%;
      }

      @include mq($until: tablet) {
        width: 100% !important;
        text-align: center;
        padding-left: 0;
      }

      .price-holder {
        float: none;
        font-size: 26px;
        font-weight:400;

        .old-price {
          color: #a3a3a3;
          font-size: 16px;
          padding-top: 11px;
        }

        @include mq($until: tablet) {
          > div {
            float: none;
            display: inline-block;
          }
        }
      }

      .delivery {
        margin-bottom: 36px;
        font-size: 12px;

        a {
          text-decoration: none;
          border-bottom: 1px solid black;

          &:hover {
            border-bottom: none;
          }
        }
      }

      .social-menu {
        > span {
          margin-right: 7px;
        }
        text-align: right;

        @include mq($until: desktop) {
          text-align: left !important;
        }
      }

      .product-info {
        position: relative;

        .form-group {
          margin-bottom: 10px;

          label {
            font-size: 12px;
            font-weight: 500;
            padding-bottom: 3px;
          }
        }


        #out-of-stock {
          color: $color-red;
          font-size: 24px
        }


        h1 {
          text-transform: uppercase;
          font-size: 36px;
          margin-top: -5px;
          margin-bottom: 5px;
          font-weight: 400;

          @include mq($from: tablet) {
            padding-right: 90px;
          }

          @include mq($until: tablet) {
            margin-top: 10px;
          }

          a {
            cursor: pointer;
          }
        }

        h2 {
          margin-top: 0;
          margin-bottom:30px;
          font-size: 16px;
          font-weight:400;
        }

        h3 {
          margin-top: 0;
          margin-bottom:13px;
          color: #ccc;
          font-size: 12px;
          font-weight:300;
        }

        .boutique {
          position: absolute;
          right: 15px;
          top: 30px;
          text-align: center;

          @include mq($until: tablet) {
            display: none;
          }

          img {
            margin-bottom: 10px;
            max-width: 70px;
            max-height: 70px;
          }

          span {
            display: block;
            font-size: 12px;
            color: #aaa;
          }

          a {
            display: block;
            font-size: 10px;
            text-decoration: underline;
            color: #aaa;
          }
        }

        .buy-box {
          margin-top: 5px;

          @include mq($until: tablet) {
            margin-top: 10px;
          }

          > div {
            position: relative;

            .outside {
              position: absolute;
              top: 0;
              margin-left: 20px;
              line-height: 36px;
              left: 100%;
              text-align: left;
              width: 100%;

              @include mq($until: tablet) {
                position: relative;
                top: initial;
                left: initial;
                width: 100% !important;
                display: block;
                text-align: right !important;
                margin-top: 10px !important;
                margin-left: 0px !important;
                margin-bottom: -10px;
              }
            }
          }

          button {
            height: 42px;
          }
        }
      }
    }

    .product-tabs {
      margin-top: 2px;
      margin-bottom: 30px;

      .tab-content {
        text-align: left;
      }

      .nav-tabs {

        a {
          margin-bottom: 16px;
          padding: 10px 0px 6px 24px;
          font-weight: 600;
          font-size: 12px;
          color: #000;
          background: url(https://d26scq1gkiosrf.cloudfront.net/newmood/ruler.PNG) no-repeat left top;
          text-decoration: underline;
        }
        li {

          a {
            font-size: 12px;
            text-transform: uppercase;
            &[data-toggle='modal'] {
              color: rgb(0, 0, 0);
              font-weight: 600;
              font-size: 12px;
              background: url('/../../images/ruler.png') left top no-repeat;
              text-decoration: underline;
              padding: 10px 0px 6px 24px;
              margin-bottom: 16px;
            }
          }

          &:first-child {
            a {
              margin-left: -15px;
            }
          }
        }
      }
    }
  }
}

.recently-viewed-products-background {
  padding-top: 50px;
  background: #f9f9f9;

  .box.product-list {
    > .header {
      .title {
        padding-left: 15px;
      }
    }

    > .inner {
      > div {
        margin-bottom: 0;
      }
    }
  }
}

#product-image {
  cursor: zoom-in;
}
.zoomContainer {
  display:none;
}

// Hack for tablet facebook share button
div.fb-share-button.fb_iframe_widget.fb_iframe_widget_fluid {
  display: inline-block !important;
}

.esto-separator {
  position: relative;
  margin: 15px 0px;
  height: 1px;
  width: 50%;
  background-color: #DCDCDC;

  > span {
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 5px 10px;
    font-size: 14px;
    line-height: 20px;
    background-color: #ffffff;
    transform: translate(-50%, -50%);
  }
}

@media screen and (max-width: 640px) {
  .esto-separator {
    width: 100%;
  }
}
