//#menu {
//  .account {
//    .img {
//      height: 30px;
//      width: 30px;
//      display: inline-block;
//      background-repeat: no-repeat;
//      background-size: cover;
//      background-position: center;
//      vertical-align: middle;
//      border-radius: 50%;
//    }
//  }
//}

.cookie-notification {
  background-color: #ffffff;
  color: #000000;
  min-height: 50px;
  text-align: center;
  padding: 10px;
  z-index: 1002;
  left: 0;
  right: 0;
  width:100%;
  bottom: 0;
  position: fixed;
  font-style: italic;
  font-size: 13px;
  .cookie-link a {
    text-decoration: underline;
  }
  .cookie-link a:hover {
    text-decoration: none;
  }
}

@media screen and (max-width: 767px) {
  .cookie-notification .container {
    width: 100% !important;
  }
}

.cookie-notification .container {
  width: 80%;
}

.cookie-text {
  width: 80%;
  margin: 0 auto;
  display: inline-block;
}

.x-button-parent {
  position: absolute;
  display: inline-block;
  margin-left: 8%;
  top: 35%;
}

#notification-button, #decline-btn {
  margin-left: 10px;
  padding: 5px;
  font-style: initial;
  font-size: 13px;
  text-decoration: underline;
}
.decline-dropdown {
  padding: 5px;
  font-style: initial;
  font-size: 13px;
}
.mt-05 {
  margin-top: 0.5em;
}

/* CLOSE BUTTON */

.x-close-button {
  position: relative;
  width: 23px;
  height: 23px;
  border: 2px solid #000000;
  background-color: #ffffff;
  border-radius: 50%;
  float: right;
}
.x-close-button::before, .x-close-button::after {
  position: absolute;
  top: 8px;
  left: 3px;
  width: 13px;
  height: 3px;
  content: "";
  background-color: #000000;
  display: block;
}
.x-close-button::before {
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.x-close-button::after {
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.x-close-button:hover { cursor: pointer; }

/* CLOSE BUTTON */

//.cart-bg {
//  #navbar-toggle-global {
//    top: 13px;
//  }
//}

#navbar-toggle-global {
  position: absolute;
  z-index: 1001;
  left: 15px;
  top: 46px;

  &.active {
    right: calc(7% - 22px);
    left: initial;
  }
}

.accountHeader{
  + .container-fluid {
    .box.header{
      margin-top: 47px;
      @media screen and (min-width: 768px) and (max-width: 991px){
        margin-top: 47px;
      }
    }
  }
}

.box.header {
  @media screen and (min-width: 768px) and (max-width: 991px) {
    margin-top: 47px;
  }
}

@import "cart";