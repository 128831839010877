.box.header {
  margin-bottom: 0;

  &.group,
  &.brand {
    padding-bottom: 12px;
  }

  @include mq($until: mobile) {
    margin-bottom: 0;
  }

  > .header {
    > .title {
      font-size: 32px;

      @include mq($from: mobile, $until: desktop) {
        width: 80%;
      }

      @include mq($until: mobile) {
        text-align: center;
        margin-right: 0;
      }
    }
  }

  &.solid-color {
    .links {

      &.acc-header-list {
        padding-left: 30px;
        padding-right: 30px;
      }

      li {

        &.active,
        &:hover {
          a {
            background: white !important;
          }
        }

        a {
          background: #E3E3E3 !important;
          opacity: 1 !important;
        }
      }
    }
  }

  > .mobile {
    display: none;

    @include mq($until: tablet) {
      display: block;
      margin-top: 25px;
    }
  }

  > .inner {
    //border-radius: $global-border-radius;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    //min-height: 269px;
    background-color: #F5F5F5;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-items: center;
    align-items: center;
    width: 100%;

    .tabs-none {
      display: none;
    }

    @include mq($until: tablet) {
      display: none;

      .tabs-none {
        display: block;
      }
    }

    .product-filter-sort {
      display: none;
    }

    .shops {
      .title {
        position: absolute;
        top: 40px;
        left: 0px;
        right: 0px;
        width: 100%;
        text-align: center;
        font-size: 40px;
        text-transform: uppercase;
        font-weight: bold;
      }
    }

    .account {
      .image {
        @include mq($until: tablet) {
          display: none;
        }
        position: absolute;
        top: -30px;
        left: 0px;
        right: 0px;
        margin: auto;
        width: 60px;
        height: 60px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 50%;
        overflow: hidden;
        z-index:1001;
      }

      .title {
        position: absolute;
        top: 50px;
        left: 0px;
        right: 0px;
        width: 100%;
        text-align: center;
        font-size: 40px;
        text-transform: uppercase;
        font-weight: bold;

        &.banner {
          color:white;
          top: 120px;
        }
      }

      .name {
        position: absolute;
        top: 95px;
        left: 0px;
        right: 0px;
        width: 100%;
        text-align: center;
        font-size: 11px;
        font-style: italic;
        font-weight: bold;
      }
    }

    .help {
      color: #000;

      .title {
        position: absolute;
        top: 40px;
        left: 0px;
        right: 0px;
        width: 100%;
        text-align: center;
        font-size: 32px;
        text-transform: uppercase;
        font-weight: bold;
      }

      .comment {
        position: absolute;
        top: 80px;
        left: 0px;
        right: 0px;
        width: 100%;
        text-align: center;
        font-size: 14px;
        font-style: italic;
        font-weight: bold;

        a {
          text-decoration: underline;
          color: inherit;
        }
      }
    }

    .links {
      position: absolute;
      left: 50px;
      right: 50px;
      bottom: 0px;

      li {
        a {
          padding: 13px;
          display: inline-block;
          min-width: 170px;
          background: white;
          margin: 0px 5px;
          border-radius: 3px 3px 0px 0px;
          text-align: center;
          font-weight: bold;
          text-transform: uppercase;
          opacity: 0.9;
          color: #777777;

          @include mq($from: tablet, $until: desktop){
            min-width: initial;
          }
        }

        &.active,
        &:hover {
          a {
            color: #000;
            opacity: 1;
            text-decoration: none;
          }
        }
      }
    }
    .left-side {
      display: table-cell;
      width: 50%;
      vertical-align: middle;
      position: absolute;
      left: 0;
      top: 23%;
      &.long-desc {
        position: relative;
        top: 0px;
      }
    }
    .brand-pic {

      width: 50%;
      height: 100%;
      background-position: center;
      background-size: cover;
      display: table-cell;
      position: absolute;
      right: 0;
    }
    .brand-description {
      width: 90%;
      margin: 0 auto;
      text-align: center;
      .catalog-intro {
        &.truncated {
          position: relative;
          max-height: 80px;
          overflow: hidden;
          &:after {
            content: '';
            display: inline-block;
            position: absolute;
            width: 100%;
            height: 15px;
            right: 0px;
            top: 65px;
            background-image: url('../../../images/brand_gradient.png');
            background-size: 2px 15px;
            background-repeat: repeat-x;
          }
        }
      }
      .show-more {
        margin-top: 5px;
        display: block;
        text-align: right;
        font-style: italic;
        text-decoration: underline;
        &:hover {
          cursor: pointer;
        }
      }
    }
    .logo {
      width: 100px;
      height: 100px;
      background-color: #464646;
      display: inline-block;
      margin-left: 40%;
      position: relative;
      margin-top: 20px;
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);

      img {
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        margin: auto;
      }
    }
  }
}



.all-items-header {
  .title {
    font-size: 32px;
    font-weight: bold;
    color: black;
    text-transform: uppercase;
    margin-right: 15px;
    margin-bottom: 10px;
  }
}