.catalogue-menu {
  margin-top: 10px;

  &.filter {
    //margin-top: -90px;
  }

  ul {
    list-style: none;
    padding: 0 0 14px 0;

    li {

      a {
        padding-top: 4px;
        padding-bottom: 4px;
        display: block;

        &.active,
        &:hover {
          font-weight: bold;
          text-decoration: none;

        }
      }

      .submenu {
        padding: 0px 0px 0px 20px;

        li {
          a {
            font-weight: 400;

            &.active,
            &:hover {
                font-weight: bold;
            }
          }
        }
      }
    }
  }
}

.catalogue-intro-wrp {
  .catalog-intro {
    &.truncated {
      position: relative;
      max-height: 60px;
      overflow: hidden;
      &:after {
        content: '';
        display: inline-block;
        position: absolute;
        width: 100%;
        height: 15px;
        right: 0px;
        top: 45px;
        background-image: url('../../../images/catalog_gradient.png');
        background-size: 2px 15px;
        background-repeat: repeat-x;
      }
    }
  }
  .show-more {
    margin-top: 5px;
    display: block;
    text-align: right;
    font-style: italic;
    text-decoration: underline;
    &:hover {
      cursor: pointer;
    }
  }
}