.box.category {
  padding: 15px 15px 0 15px;
  margin: 0;

  @include mq($until: tablet) {
    margin-bottom: 30px;
    padding-right: 0;
    padding-left: 0;

    > .inner {
      border-radius: 0 !important;
    }
  }

  > .inner {
    padding: 30px;
    position: relative;
    border-radius: $border-radius;
    overflow: hidden;

    .bottom {
      position: absolute;
      bottom: 45px;
      width: 100%;
    }

    .title-middle {
      position: absolute;
      width: 70%;
      top: 280px;
      color: white;

      > div {
        font-size: 32px;
        font-weight: 900;
        text-transform: uppercase;
      }

      > span {
        font-style: italic;
        font-weight: bold;
      }

      .labels {
        position: absolute;
        top: -40px;

        span {
          font-size: 12px;
          font-weight: normal;
        }
      }
    }

    .box.two-list {
      > .header {
        padding: 0;
      }

      li {
        line-height: 30px;
      }
    }
  }
}