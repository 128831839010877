.menu-mobile-offcanvas {
  display: none;
  position: absolute;
  //position: fixed;
  width: 100%;
  background: #f3f3f4;
  left: 0;
  z-index: 9999;
  top: 0;
  min-height: 100%;
  //height: 100%;
  //overflow-y: auto;
  padding: 20px 30px 45px 30px;

  .logo {
    margin-bottom: 10px;
    text-align: center;

    img {
      max-height: 15px;
    }
    span {
      display: none;
    }
  }
  .menu {
    //position: absolute;
    //top: 0;
    //left: 0;
    margin-left: -30px;
    margin-right: -30px;
    width: 100vw;
    border-bottom: 1px solid $border-grey;

    ul {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      padding: 0px;
      margin: 0px;
      list-style: none;

      li {
        //width:50%;
        //float: left;
        flex-grow: 1;
        text-align: center;

        &:last-child {
          //border-left: 1px solid $border-grey;
        }

        a {
          width: 100%;
          padding-top: 15px;
          padding-bottom: 15px;
          display: block;

          &:hover {
            text-decoration: none;
          }

          &.active {
            border-bottom: 3px solid $color-black;
          }

          &.bold,
          span b {
            font-weight: 500;
          }
        }
      }
    }
  }

  .menu-back {
    display: none;
    position: relative;
    left: 0px;
    top: 0px;
    text-transform: uppercase;
    color: $color-black;
    cursor: pointer;
    font-size: 13px;

    &:before {
      font: normal normal normal 20px / 1 FontAwesome;
      content: "\f104";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      vertical-align: middle;
      height: 23px;
    }

    span {
      padding-left: 20px;
    }
  }

  .menu-user {
    margin-left:-12px;
    margin-right:-12px;
    border-top: 1px solid #e7e7e8;
    padding-top:20px;

    .btn {
      margin-top: 0;
      padding-top: 10px;
      padding-bottom: 10px;

      i {
        margin-top: 3px;

        &.shopping-bag-bg {
          margin-right: 2px;
        }
      }
    }
  }

  .menu-main,
  .menu-sub {
    margin-bottom: 20px;
    margin-top: 15px;

    ul {
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        position: relative;

        a {
          display: block;
          padding: 10px;
          margin-left: -10px;
          margin-right: -10px;

          &:hover {
            text-decoration: none;
          }

          &.bold,
          span b {
            font-weight: 500;
          }

          span.total {
            float: right;
            color: $color-grey;
          }

          i {
            float: right;
            font-size: 24px;
            margin-top: -4px;
          }
        }
      }
    }
  }

  .menu-sub {
    > div {
      display: none;
    }
  }
}

#menu-mobile-offcanvas-filter {
  display: none;
  left: -100%;
}

@media (max-width: $screen-md-max) {
  body.menu-mobile-open {
    .menu-mobile-offcanvas {
      left: 0;
    }
    overflow: hidden;
    width: 100%;
    height: 100%;

    main {
      position: fixed;
      width: 100%;
      overflow: hidden;
      left: 85%;
    }

    footer {
      z-index: -9999;
    }
  }

  main {
    left: 0;
  }

  html.menu-mobile-open {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}